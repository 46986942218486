<template>
  <div
    :class="containerClasses"
    :style="spinnerContainerStyle"
  >
    <div
      :class="[spinnerClasses.join(' '), classes]"
      :style="spinnerStyle"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "spinner",
  props: {
    alignment: {
      type: String,
      validator: function (value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
      default: 'center'
    },
    type: {
      type: String,
      validator: function (value) {
        return ['pr','se','su','da','wa','in','li','dar'].indexOf(value) !== -1
      },
      default: 'pr'
    },
    size: {
      type: [Number, String],
      default: 30
    },
    thickness: {
      type: [Number, String],
      default: 15
    },
    containerClasses: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },

  computed: {
    spinnerClasses: function () {

      const typeMap = {
        pr: 'primary',
        se: 'secondary',
        su: 'success',
        wa: 'warning',
        da: 'danger',
        in: 'info',
        li: 'light',
        dar: 'dark'
      };

      const classes = [
        'spinner-border',
        `text-${typeMap[this.type]}`
      ];

      return classes;
    },

    spinnerContainerStyle: function () {
      return {
        display: 'flex',
        justifyContent: this.alignment
      }
    },

    spinnerStyle: function () {
      return {
        fontSize: `${this.thickness}px`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    }
  }
}
</script>

<style scoped>
</style>
