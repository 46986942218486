<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
          this.showModal=false;
          this.$emit('user-action-hide', id);
      }"
    >
      <form class="row">

        <!--page link-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Link:
            <span class="text-danger">*</span>
            <form-input-help>Page url excluding domain name</form-input-help>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Page Link"
            v-model.trim="form.link"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.link" />
        </div>

        <!--title-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Title:
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Page Title"
            v-model.trim="form.title"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.title" />
        </div>

        <!--keywords-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Keywords:
            <span class="text-danger">*</span>
            <form-input-help>Insert comma (,) to add keyword</form-input-help>
          </label>
          <tags-input
            element-id="keywords"
            placeholder="Add Keyword"
            v-model="form.keywords"
            add-tags-on-comma
          />
          <form-error-msg :error="formError.keywords" />
        </div>

        <!--description-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Description:
            <span class="text-danger">*</span>
          </label>
          <textarea
            class="form-control"
            placeholder="Meta Description"
            v-model.trim="form.description"
            cols="30"
            rows="2"
            required
          ></textarea>
          <form-error-msg :error="formError.description" />
        </div>

        <!--copyright-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Copyright:
          </label>
          <textarea
            class="form-control"
            placeholder="Copyright Text"
            v-model.trim="form.copyright"
            cols="30"
            rows="2"
            required
          ></textarea>
        </div>

        <!--thumbnail-->
        <div class="form-group col-12">
          <label class="col-form-label">
            Thumbnail:
          </label>
          <div class="custom-file">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="upload Image"
                :value="thumbnailPreview.name"
                readonly
                @click="triggerClick('uploadThumbnail')"
              >
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  @click="triggerClick('uploadThumbnail')"
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    class="d-none"
                    ref="uploadThumbnail"
                    @change="handleFileSelect"
                  >
                  Choose Image
                </button>
              </div>
            </div>
          </div>

          <div
            class="preview mt-2"
            v-if="form.thumbnail"
          >
            <div class="card">
              <div class="card-img-top user-uploaded-image text-center">
                <!--<img-->
                <!--  class="img-fluid thumb-image"-->
                <!--  v-if="editing"-->
                <!--  :src="filePreviewObj"-->
                <!--  alt="SEO Meta Image"-->
                <!--/>-->
                <img
                  class="card-img-top img-fluid img-thumbnail thumb-image"
                  :src="thumbnailPreview.image"
                  alt="SEO Meta Image"
                />
              </div>
            </div>
          </div>

        </div>

      </form>

      <template #submit-btn-content>
        <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
        {{ modal.submitBtnText }}
      </template>
    </modal-comp>
  </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import '@voerro/vue-tagsinput/dist/style.css';
import TagsInput from '@voerro/vue-tagsinput';

export default {
  name: "SEO-action",
  components: {FormErrorMsg, Treeselect, ModalComp, FormInputHelp, TagsInput},
  props: {
    id: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    seo: {
      type: [Object, Boolean]
    },
    links: {
      type: Array,
      required: true
    },

  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'New SEO Meta',
        headerClasses: '',
        closeBtnClasses: '',
        submitBtnText: 'Add Meta'
      },
      editing: false,

      form: {
        link: '',
        title: '',
        keywords: [],
        description: '',
        copyright: '',
        thumbnail: '',
        charset: 'utf-8',

        submitting: false
      },

      options: {
        links: [],
      },

      formError: {
        link: false,
        title: false,
        keywords: false,
        description: false,
      }
    };
  },

  validations: {
    form: {
      link: {
        required: required
      },
      title: {
        required: required,
        minLength: minLength(3)
      },
      keywords: {
        required,
        minLength: minLength(1)
      },
      description: {
        required,
        minLength: minLength(10)
      }
    }
  },

  computed: {
    thumbnailPreview () {

      const thumbFile = this.form.thumbnail;

      if (typeof thumbFile === 'string' && thumbFile.length < 1) return {};

      if (typeof thumbFile === 'string') {
        return {
          image: `/storage/seo/${thumbFile}`,
          name: thumbFile
        }
      } else {
        return {
          image: URL.createObjectURL(thumbFile),
          name: thumbFile.name
        }
      }
    }
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
    seo(nv) {

      this.editing = !!nv;

      this.populateForUpdate(nv);

    },

    // error handling
    'form.link': function () {
      this.formError.link = ErrorHelper.errAll(this.$v, 'form.link');
    },
    'form.title': function () {
      this.formError.title = ErrorHelper.errAll(this.$v, 'form.title');
    },
    'form.keywords': function () {
      this.formError.keywords = ErrorHelper.errAll(this.$v, 'form.keywords');
    },
    'form.description': function () {
      this.formError.description = ErrorHelper.errAll(this.$v, 'form.description');
    },
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    triggerClick(refName) {
      const elem = this.$refs[refName];
      elem.click();
    },

    handleFileSelect() {

      // store file in form
      this.form.thumbnail = this.$refs.uploadThumbnail.files[0];
    },

    async handleSubmit() {

      // check if form has any error
      if (this.$v.$invalid) {

        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });

        return false;
      }

      const response = await this.sendData();

      if (response) {

        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: `SEO Meta ${!this.editing ? 'Created' : 'Updated'}`,
          body: `SEO Meta ${!this.editing ? 'created' : 'updated'} successfully`,
          type: 'su',
          autoHideDelay: 1,
          closeCallback: () => window.location.reload()
        });

        // close modal
        this.showModal = false;
      }
    },

    async sendData() {

      this.form.submitting = true;

      const endPoint = (this.editing)
        ? `/admin/seo-meta/${this.seo.id}`
        : `/admin/seo-meta`
      ;
      const method = (this.editing) ? 'patch' : 'post';

      let requestConfig = {
        headers: {'Content-Type': 'multipart/form-data'}
      };

      const requestData = new FormData();
      requestData.append('link', this.form.link);
      requestData.append('title', this.form.title);
      requestData.append('description', this.form.description);
      requestData.append('copyright', this.form.copyright);
      requestData.append('charset', this.form.charset);
      requestData.append('_method', method);

      this.form.keywords.map(keyword => {
        requestData.append('keywords[]', keyword.value);
      });

      // only add image if changed / selected
      if (typeof this.form.thumbnail !== 'string') {
        requestData.append('thumbnail', this.form.thumbnail);
      }

      try {

        const res = await axios.post(endPoint, requestData, requestConfig);

        this.form.submitting = false;
        return res;

      } catch (e) {

        this.form.submitting = false;

        // show notification about error
        NotificationHelper.showServerError(this.$snotify, e);

        // map errors to display in form
        ErrorHelper.mapServerError(this.formError, e, true);

        return false;
      }

    },

    populateForUpdate(nv) {

      if (!nv) return;

      // customize modal
      this.modal = {
        title: 'Update SEO Meta',
        headerClasses: 'bg-warning text-dark',
        closeBtnClasses: 'text-dark',
        submitBtnText: 'Save Changes'
      };

      // populate values
      this.form.link = nv.link;
      this.form.keywords = nv.keywords
        .map(keyword => ({
          key: keyword.trim(),
          value: keyword.trim()
        }))
      ;
      this.form.description = nv.description;
      this.form.copyright = nv.copyright;
      this.form.thumbnail = nv.thumbnail || '';
      this.form.charset = nv.charset;
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
.thumb-image {
  max-height: 200px;
  width: auto;
}
</style>
<style>
.tags-input-badge {
  font-size: 100%;
}
.tags-input-remove:before, .tags-input-remove:after {
  background: #007ae1;
}
.tags-input-wrapper-default.active {
  border: 1px solid #007ae1;
   -webkit-box-shadow: unset;
   box-shadow: unset;
   outline: 0 none;
}
</style>
