<template>
    <div>
        <slot :open-modal="openModal"></slot>

        <modal-comp
            :id="id"
            :show="showModal"
            :title="modal.title"
            :header-classes="modal.headerClasses"
            :close-btn-classes="modal.closeBtnClasses"
            @submit="handleSubmit"
            @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
            @shown="populateForUpdate(opening, true)"
        >
            <form class="row">
                <div class="form-group col-12">
                    <label for="day" class="col-form-label">
                        For Days:
                        <span class="text-danger">*</span>
                    </label>
                    <treeselect
                        id="day"
                        placeholder="Select a parent category"
                        v-model="form.days"
                        :options="form.dayOptions"
                        :multiple="!updating"
                        :searchable="false"
                        :clearable="false"
                        :disabled="updating"
                    />
                    <form-error-msg :error="formError.days" />
                </div>
                <div class="form-group col-12">
                    <label for="day" class="col-form-label">
                        Hours:
                        <span class="text-danger">*</span>
                    </label>
                    <form-error-msg :error="formError.hours" />
                </div>
                <div
                    v-for="(times, idx) in form.hours"
                    class="input-group hours-group"
                >
                    <div class="form-group col-12 mb-0 mt-2 text-primary hours-group-heading">
                        <span>Timing #{{ idx }}</span>
                        <span
                            class="icon-remove_circle text-dark hours-group-heading-icon"
                            @click="handleAddRemove(times.id)"
                        ></span>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="day" class="col-form-label">
                            From
                        </label>
                        <form-time-picker
                            placeholder="Select Start Time"
                            :value="times.from"
                            @change="({displayTime})=>times.from=displayTime"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="day" class="col-form-label">
                            To
                        </label>
                        <form-time-picker
                          placeholder="Select End Time"
                          :value="times.to"
                          @change="({displayTime})=>times.to=displayTime"
                        />
                    </div>
                    <div class="form-group col-6">
                        <label class="col-form-label">
                            Delivery:
                            <form-input-help>Disabling will hide the category from users</form-input-help>
                        </label>
                        <div>
                            <switch-comp
                                :checked="times.enabled_delivery"
                                @change="val=>times.enabled_delivery=val"
                                enable-text="Enabled"
                                disable-text="Disabled"
                            />
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class="col-form-label">
                            Collection:
                            <form-input-help>Disabling will hide the category from users</form-input-help>
                        </label>
                        <div>
                            <switch-comp
                                :checked="times.enabled_collection"
                                @change="val=>times.enabled_collection=val"
                                enable-text="Enabled"
                                disable-text="Disabled"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group col-12 mt-2">
                    <button
                        class="btn btn-primary btn-block"
                        type="button"
                        @click="handleAddRemove()"
                    >
                        <i class="icon-add-to-list"></i> New
                    </button>
                </div>

            </form>
            <template #submit-btn-content>{{ modal.submitBtnText }}</template>
        </modal-comp>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import { formatDate } from '../../js/helpers/Helper';
import BadgeComp from '../Utility/BadgeComp';
import FormTimePicker from '../Utility/FormTimePicker';
import SwitchComp from '../Utility/SwitchComp';
import { formatTimePickerCompatibleTime } from '../../js/helpers/Helper';

export default {
    name: "opening-timing-action",
    components: {FormTimePicker, BadgeComp, FormErrorMsg, Treeselect, ModalComp, FormInputHelp, SwitchComp},
    props: {
        id: {
            type: String,
            required: true
        },
        opening: {
            type: [Object,Boolean],
            default: false
        },
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showModal: false,
            modal: {
                title: 'New Timing',
                headerClasses: '',
                closeBtnClasses: '',
                submitBtnText: 'Add Timing'
            },

            form: {
                days: [],
                dayOptions: [
                    {id: 'sun', label: 'Sunday'},
                    {id: 'mon', label: 'Monday'},
                    {id: 'tue', label: 'Tuesday'},
                    {id: 'wed', label: 'Wednesday'},
                    {id: 'thu', label: 'Thursday'},
                    {id: 'fri', label: 'Friday'},
                    {id: 'sat', label: 'Saturday'},
                ],
                hours: [],
                submitting: false
            },
            formError: {
                days: false,
                hours: false
            },

            updating: false
        };
    },

    validations: {
        form: {
            days: {
                required,
                minLength: minLength(1)
            },
            hours: {
                required,
                minLength: minLength(1)
            }
        }
    },

    watch: {
        show: function (nv) {
            this.showModal = nv;
        },
        opening(nv) {
            if (!nv) return;

            this.populateForUpdate(nv);

            this.updating = !!nv;
        },
        // error handling
        'form.days': function() {
            this.formError.days = ErrorHelper.errAll(this.$v, 'form.days');
        },
        'form.hours': function() {
            this.formError.hours = ErrorHelper.errAll(this.$v, 'form.hours');
        }
    },

    methods: {
        openModal() {
            this.showModal = true;
        },

        async handleSubmit() {

            // check if form has any error
            if (this.$v.$invalid) {

                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: 'Invalid Data',
                    body: 'Please fill the form correctly before submitting',
                    type: 'er'
                });

                return false;
            }

            const response = await this.sendData();

            if (response) {

                // show success notification
                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: `Timing ${this.updating? 'Updated':'Added'}`,
                    body: `Timing hours ${this.updating ? 'updated':'added'} successfully`,
                    type: 'su',
                    autoHideDelay: 2,
                    closeCallback: () => window.location.reload()
                });

                // close modal
                this.showModal = false;
            }
        },

        async sendData() {

            this.form.submitting = true;

            const endPoint = this.updating
                ? `/admin/opening/${this.opening.id}`
                : '/admin/opening'
            ;
            const method = this.updating ? 'patch' : 'post';

            const requestData = {
                days: this.form.days,
                hours: this.form.hours
            };

            try {

                const res = await axios[method](endPoint, requestData);

                this.form.submitting = false;
                return res;

            } catch (e) {

                this.form.submitting = false;

                // show notification about error
                NotificationHelper.showServerError(this.$snotify, e);

                // map errors to display in form
                ErrorHelper.mapServerError(this.formError, e, true);

                return false;
            }

        },

        populateForUpdate(opening, includeTime) {
            if (!opening) return;

            // populate data
            this.form.days = [];
            this.form.days.push(opening.day);
            this.form.hours = this.formatHourOptions(opening.hours, includeTime);

            // customize UI
            this.modal.title = 'Update Timing';
            this.modal.submitBtnText = 'Save Changes';
            this.modal.headerClasses = 'bg-warning text-dark';
            this.modal.closeBtnClasses = 'text-dark';
        },

        handleAddRemove(itemId) {

            if (itemId || itemId === 0) {
                // remove the item
                const idx = this.form.hours.findIndex(itm => itm.id === itemId);
                this.form.hours.splice(idx, 1);
            } else {
                // add new item
                const newTime = {
                    id: Date.now(),
                    from: '',
                    to: '',
                    enabled_delivery: true,
                    enabled_collection: true,
                };

                this.form.hours.push(newTime);
            }
        },

        formatHourOptions(hours, withTime=false) {
            return hours.map(({id, from, to, enabled_delivery, enabled_collection}) => {
                return {
                    id,
                    from: withTime ? formatTimePickerCompatibleTime(from) : '',
                    to: withTime ? formatTimePickerCompatibleTime(to) : '',
                    enabled_delivery: !!enabled_delivery,
                    enabled_collection: !!enabled_collection
                };
            });
        }
    }
}
</script>

<style scoped>
.hours-group {
    background-color: #9e9e9e3d;
    margin-bottom: 10px;
    border-radius: 10px;
}
.hours-group-heading {
    display: flex;
    justify-content: space-between;
}
.hours-group-heading-icon {
    font-size: 25px;
    cursor: pointer;
}
</style>
