<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">SEO Meta Settings</div>
          <SEO-action
            id="addSeo"
            :links="links"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </SEO-action>
        </div>
        <div class="card-body">

          <!--                    list-->
          <data-table
            id="seoList"
            :column-map="columnMap"
            :data="allSeo"
            @actionClicked="handleAction"
          />

          <!--                    actions-->
          <SEO-action
            id="editSeo"
            :seo="actionSubject.subject"
            :links="links"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />


          <delete-action
            :confirm-body="`This will delete this SEO setting permanently.`"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/seo-meta"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteAction from '../DoActions/DeleteAction';
import DataTable from '../Utility/DataTable';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';
import SEOAction from '../DoActions/SEOAction';

export default {
  name: "seo-page-content",
  props: {
    allSeo: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    },
    uiUrl: {
      type: String,
      required: true
    }
  },
  components: {SEOAction, DeleteAction, DataTable},
  data() {
    return {
      columnMap: [
        {data: 'link', title: 'Link', render: this.renderLink},
        {data: 'title', title: 'Title'},
        {data: 'keywords', title: 'Keywords', render: this.renderKeywords},
        {data: 'description', title: 'Description'},
        {data: 'copyright', title: 'Copyright'},
        {data: 'thumbnail', title: 'Thumbnail', render: this.renderThumbnail},
        {data: 'charset', title: 'Charset'},
        {data: 'id', title: 'Actions', render: this.renderAction},
      ],

      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  computed: {
    links: function () {
      return this.allSeo.map(seo => seo.link);
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.allSeo.find(itm => itm.id === id),
        action: action,
      };
    },

    renderLink(data) {
      const path = `${this.uiUrl}${data}`;
      return `<a href="${path}" target="_blank" class="text-primary">${data}</a>`
    },

    renderKeywords(data) {

      const keywordBadges = data
        .map(word => `<span class="badge badge-dark mr-1">${word}</span>`)
        .join('')
      ;

      return keywordBadges;
    },

    renderThumbnail(data) {

      if (!data) return '';

      const path = `${window.location.origin}/storage/seo/${data}`;

      return `<a href="${path}" target="_blank">
        <img class="img img-thumbnail seo-thumbnail" src="${path}" />
      </a>`;
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param data
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(data, type, row) {

      let actions = '';

      if (this.permissions.update) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-edit1"></i>
          </span>
        `;
      }

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      return actions;
    },
  }
}
</script>

<style>
.table-badge {
  font-size: 12px;
}
.seo-thumbnail {
  max-height: 40px;
  max-width: 100px;
}
</style>
