<template>
  <div class="social-tile h-165">
    <div class="social-icon bg-info" :class="iconBg">
      <i :class="icon"></i>
    </div>
    <div>{{title}}</div>
    <h2>{{ content }}</h2>
  </div>
</template>
<script>
export default {
  name : 'tile-card',
  props : {
    iconBg : {
      type : String,
      required : false,
      default : 'bg-info'
    },

    icon  : {
      required: false,
      type : String,
    },

    title  : {
      type : String,
      required : false
    },

    content : {
      required : false
    }
  }
}
</script>
