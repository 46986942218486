<template>
    <div>
        <slot :open-modal="openModal"></slot>

        <modal-comp
            :id="id"
            :show="showModal"
            :title="modal.title"
            :header-classes="modal.headerClasses"
            :close-btn-classes="modal.closeBtnClasses"
            @submit="handleSubmit"
            @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
        >
            <form class="row">
                <div class="form-group col-12">
                    <label for="addonName" class="col-form-label">
                        Addon Name:
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="addonName"
                        placeholder="Item addon name"
                        v-model.trim="form.addonName"
                        autocomplete="off"
                        required
                    >
                    <form-error-msg :error="formError.addonName" />
                </div>
                <div class="form-group col-6">
                    <label for="price" class="col-form-label">
                        Price:
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="number"
                        step="0.01"
                        class="form-control"
                        id="price"
                        placeholder="Sort position (descending)"
                        v-model.trim="form.price"
                        autocomplete="off"
                        required
                    >
                    <form-error-msg :error="formError.price" />
                </div>
                <div class="form-group col-6">
                    <label for="sort" class="col-form-label">
                        Sort:
                        <span class="text-danger">*</span>
                        <form-input-help>Works on descending order</form-input-help>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        id="sort"
                        placeholder="Sort position (descending)"
                        v-model.trim="form.sort"
                        autocomplete="off"
                        required
                    >
                    <form-error-msg :error="formError.sort" />
                </div>
                <div class="form-group col-6">
                    <label for="type" class="col-form-label">
                        Type:
                        <form-input-help>Type 'item' will make it available to menu</form-input-help>
                    </label>
                    <treeselect
                        id="type"
                        placeholder="Select a type addon"
                        v-model="form.select.type"
                        :options="form.select.options"
                        :multiple="false"
                        :searchable="false"
                        :clearable="false"
                    />
                    <form-error-msg :error="formError.type" />
                </div>
                <div class="form-group col-6">
                    <label class="col-form-label">
                        Enable Addon:
                        <span class="text-danger">*</span>
                        <form-input-help>Disabling will hide the addon from users</form-input-help>
                    </label>
                    <div>
                        <switch-comp
                            :checked="form.status"
                            @change="val=>form.status=val"
                            enable-text="Enabled"
                            disable-text="Disabled"
                        />
                    </div>
                </div>
            </form>

            <template #submit-btn-content>
                <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
                {{ modal.submitBtnText }}
            </template>
        </modal-comp>
    </div>
</template>

<script>
import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import SwitchComp from '../Utility/SwitchComp';
import { mustContain } from '../../js/CustomValidators';

export default {
    name: "addon-action",
    components: {FormErrorMsg, Treeselect, ModalComp, FormInputHelp, SwitchComp},
    props: {
        id: {
            type: String,
            required: true
        },
        types: {
            type: Array,
            required: true
        },
        addon: {
            type: [Object,Boolean]
        },
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showModal: false,
            modal: {
                title: 'New Addon Item',
                headerClasses: '',
                closeBtnClasses: '',
                submitBtnText: 'Add Addon'
            },

            form: {
                addonName: '',
                price: 0.01,
                sort: 0,
                status: true,
                select: {
                    type: null,
                    options: []
                },
                submitting: false
            },
            formError: {
                addonName: false,
                type: false,
                price: false,
                sort: false
            }
        };
    },

    validations: {
        form: {
            addonName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            select: {
                type: {
                    required,
                    minLength: minLength(1)
                }
            },
            price: {
                required,
                decimal,
                mustContain: mustContain('.')
            },
            sort: {
                required,
                minValue: minValue(0)
            }
        }
    },

    watch: {
        show: function (nv) {
            this.showModal = nv;
        },
        addon: function () {
            this.populateForUpdate();
        },
        // error handling
        'form.addonName': function() {
            this.formError.addonName = ErrorHelper.errAll(this.$v, 'form.addonName');
        },
        'form.select.type': function() {
            this.formError.type = ErrorHelper.errAll(this.$v, 'form.select.type');
        },
        'form.price': function () {
            this.formError.price = ErrorHelper.errAll(this.$v, 'form.price');
        },
        'form.sort': function () {
            this.formError.sort = ErrorHelper.errAll(this.$v, 'form.sort');
        }
    },

    mounted() {
        this.makeTypesSelectOptions();
    },

    methods: {
        openModal() {
            this.showModal = true;
        },

        async handleSubmit() {

            // check if form has any error
            if (this.$v.$invalid) {

                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: 'Invalid Data',
                    body: 'Please fill the form correctly before submitting',
                    type: 'er'
                });

                return false;
            }

            const response = await this.sendData();

            if (response) {

                // show success notification
                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: `Addon ${this.addon ? 'Updated' : 'Created'}`,
                    body: `'${response.data.name}' addon ${this.addon ? 'updated':'created'} successfully`,
                    type: 'su',
                    autoHideDelay: 2,
                    closeCallback: () => window.location.reload()
                });

                // close modal
                this.showModal = false;
            }
        },

        async sendData() {

            this.form.submitting = true;

            const endPoint = (this.addon)
                ? `/admin/menu/addons/${this.addon.id}`
                : `/admin/menu/addons`
            ;
            const method = (this.addon) ? 'patch' : 'post';

            const requestData = {
                addonName: this.form.addonName,
                price: parseFloat(this.form.price).toFixed(2),
                sort: parseInt(this.form.sort),
                type: this.form.select.type,
                status: this.form.status ? '1' : '0'
            };

            try {

                const res = await axios[method](endPoint, requestData);

                this.form.submitting = false;
                return res;

            } catch (e) {

                this.form.submitting = false;

                // show notification about error
                NotificationHelper.showServerError(this.$snotify, e);

                // map errors to display in form
                ErrorHelper.mapServerError(this.formError, e);

                return false;
            }

        },

        makeTypesSelectOptions() {

            this.types.map((type) => {

                // exclude self && sub types
                this.form.select.options.push(
                    {
                        id: type.toLowerCase(),
                        label: type
                    }
                );

            });

            // preselect type
            this.form.select.type = this.types[0];
        },

        populateForUpdate() {

            // only populate for updating
            if (!this.addon) return false;

            // customize modal
            this.modal = {
                title: 'Update Addon',
                headerClasses: 'bg-warning text-dark',
                closeBtnClasses: 'text-dark',
                submitBtnText: 'Save Changes'
            };

            // populate values
            this.form.addonName = this.addon.name;
            this.form.price = this.addon.price;
            this.form.sort = this.addon.sort;
            this.form.select.type = this.addon.type;
            this.form.status = (this.addon.status==1);
        }
    }
}
</script>

<style scoped>

</style>
