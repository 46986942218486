<template>
  <vue-timepicker
    v-model="time"
    :autoScroll="autoScroll"
    :closeOnComplete="closeOnComplete"
    :fixedDropdownButton="fixedDropdownButton"
    :format="format"
    :hideDisabledHours="hideDisabledHours"
    :hideDisabledMinutes="hideDisabledMinutes"
    :hourLabel="hourLabel"
    :hourRange="hourRange"
    :inputWidth="inputWidth"
    :minuteInterval="minuteInterval"
    :minuteLabel="minuteLabel"
    :minuteRange="minuteRange"
    :placeholder="`${placeholder? placeholder : format}`"
    :secondInterval="secondInterval"
    :secondRange="secondRange"
    @change="handleChange"
    @close="handleOpenClose(false)"
    @open="handleOpenClose(true)"
  />
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "FormTimePicker",
  components: {VueTimepicker},
  props: {
    format: {
      type: String,
      default: 'hh:mm A'
    },
    minuteInterval: Number,
    secondInterval: Number,
    value: String,
    hourRange: Array,
    minuteRange: Array,
    secondRange: Array,
    hideDisabledHours: {
      type: Boolean,
      default: true
    },
    hideDisabledMinutes: {
      type: Boolean,
      default: false
    },
    closeOnComplete: {
      type: Boolean,
      default: false
    },
    hourLabel: {
      type: String,
      default: 'Hour'
    },
    minuteLabel: {
      type: String,
      default: 'Minute'
    },
    inputWidth: {
      type: String,
      default: '100%'
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    fixedDropdownButton: {
      type: Boolean,
      default: true
    },
    placeholder: String
  },
  data() {
    return {
      openStatus: false,
      time: ''
    }
  },
  watch: {
    value(nv) {
      this.time = nv;
    }
  },
  methods: {
    handleOpenClose(openStatus) {
      this.openStatus = openStatus;
      this.$emit('openStatusChange', openStatus);
    },
    handleChange(eventData) {
      this.$emit('change', eventData);
    }
  }
}
</script>

<style scoped>

</style>
