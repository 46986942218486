<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Opening Hours</div>
          <opening-timing-action
            v-if="permissions.create"
            id="addOpening"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </opening-timing-action>
        </div>
        <div class="card-body">

          <!--list-->
          <div class="table-responsive">
            <table class="table custom-table">
              <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Delivery</th>
                <th>Collection</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="opening in openings">
                <tr>
                  <td class="text-center divider text-capitalize" colspan="4">{{ opening.day }}</td>
                  <td class="divider">
                    <span
                      v-if="permissions.update"
                      class="action list-action"
                      type="button"
                      @click="changeCurrentItem(opening)"
                    >
                      <i class="icon-edit1"></i>
                    </span>
                    <span
                      v-if="permissions.delete"
                      class="action list-action"
                      type="button"
                      @click="changeCurrentItem(opening, false)"
                    >
                      <i class="icon-delete_sweep"></i>
                    </span>
                  </td>
                </tr>
                <tr v-for="hour in opening.hours">
                  <td>{{ formattedTime(hour.from) }}</td>
                  <td>{{ formattedTime(hour.to) }}</td>
                  <td>
                    <badge-comp v-if="hour.enabled_delivery" type="pr">Enabled</badge-comp>
                    <badge-comp v-else type="dar">Disabled</badge-comp>
                  </td>
                  <td colspan="2">
                    <badge-comp v-if="hour.enabled_collection" type="pr">Enabled</badge-comp>
                    <badge-comp v-else type="dar">Disabled</badge-comp>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>

          <!--actions-->
          <opening-timing-action
            id="editOpening"
            :opening="actionSubject.subject"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />


          <delete-action
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            confirm-body="This will delete the timing permanently"
            endpoint="/admin/opening"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpeningTimingAction from '../DoActions/OpeningTimingAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';
import { formatDate } from '../../js/helpers/Helper';

export default {
  name: "openings-page-content",
  props: {
    openings: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {OpeningTimingAction, DeleteAction, BadgeComp},
  data() {
    return {
      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.openings.find(itm => itm.id === id),
        action: action,
      };
    },

    formattedTime(timeString) {
      timeString = `2021-03-03 ${timeString}`;
      return formatDate(timeString, 'hh:mm A');
    },

    changeCurrentItem(subject, editing = true) {

      if (editing) {
        this.actionStatus.edit = true;
        this.actionSubject.action = 'edit';
      } else {
        this.actionStatus.delete = true;
        this.actionSubject.action = 'delete';
      }

      this.actionSubject.subject = subject;
    }

  }
}
</script>

<style>
.table-badge {
  font-size: 12px;
}

.table .divider {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  background-color: #f8f8f8;
}
</style>
