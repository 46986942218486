<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      size="xl"
      hide-footer
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @hide="()=> {
          this.showModal=false;
          this.$emit('user-action-hide', id);
      }"
    >
      <template #default="sProps">

        <form
          v-if="reservation"
          class="row"
        >
          <!--Customer info-->
          <div class="col-md-12">
<!--            <div class="lead">Reservation Details</div>-->
            <div
              v-if="!reservation.last_email_send_status"
              class="col-12"
            >
              <div class="alert alert-secondary" role="alert">
                <i class="icon-error_outline"></i>Last email sent to this reservations owner was unsuccessful.
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-active">
                <tbody>
                <tr>
                  <td class="key">Status</td>
                  <td class="val text-uppercase">
                    <span class="mr-5" :class="renderStatusClass(reservation.status)">{{reservation.status}}</span>
                  </td>
                </tr>

                <tr>
                  <td class="key">Name</td>
                  <td class="val">{{ reservation.name }}</td>
                </tr>
                <tr>
                  <td class="key">Email</td>
                  <td class="val">{{ reservation.email }}</td>
                </tr>
                <tr>
                  <td class="key">Phone</td>
                  <td class="val">{{ reservation.phone }}</td>
                </tr>
                <tr>
                  <td class="key">Total Person</td>
                  <td class="val">{{ reservation.person }}</td>
                </tr>
                <tr>
                  <td class="key">Reservation Date</td>
                  <td class="val">{{ dateFormat(reservation.datetime) }}</td>
                </tr>
                <tr>
                  <td class="key">Requested At</td>
                  <td class="val">{{ dateFormat(reservation.created_at) }}</td>
                </tr>
                <tr>
                  <td class="key">Special Note</td>
                  <td class="val">{{ reservation.note }}</td>
                </tr>
                <tr
                  v-if="reservation.found_on"
                >
                  <td class="key">Heard On</td>
                  <td class="val">{{ reservation.found_on }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </template>
    </modal-comp>
  </div>
</template>

<script>
import FormErrorMsg from '../Utility/FormErrorMsg';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import { formatDate } from '../../js/helpers/Helper';
import { CURRENCY_SYMBOL } from '../../js/helpers/Constants';
import BadgeComp from '../Utility/BadgeComp';
import vSelect from 'vue-select'

export default {
  name: "reservation-action",
  components: {BadgeComp, FormErrorMsg, Treeselect, ModalComp, FormInputHelp, 'v-select' : vSelect},
  props: {
    id: {
      type: String,
      required: true
    },
    reservation: {
      type: [Object, Boolean],
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'Reservation Details',
        headerClasses: '',
        closeBtnClasses: '',
        submitBtnText: 'Save Changes'
      },
      currencySymbol: CURRENCY_SYMBOL
    };
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
      this.statusUpdating = false
    }
  },

   methods: {
    openModal() {
      this.showModal = true;
    },

    renderStatusClass(status) {

      // available statuses: 'pending','processing','delivering','delivered','cancelled'

      switch (status) {
        case 'pending':
          return 'badge table-badge badge-dark badge-pill text-capitalize';

        case 'confirmed':
          return 'badge table-badge badge-success badge-pill text-capitalize';

        case 'cancelled':
          return 'badge table-badge badge-secondary badge-pill text-capitalize';

        default:
          return 'badge table-badge badge-danger badge-pill text-capitalize';
      }
    },

    dateFormat(timeString) {
      return formatDate(timeString, 'MMM DD  @hh:mm A');
    }
  }
}
</script>

<style scoped>
#orderStatusUpdateGroup {
  flex-wrap: nowrap;
}

.order-status-select-label {
  font-size: 18px;
  padding: 2px;
  text-transform: capitalize;
}

.key {
  background-color: #f8f8f8;
}

.val .help {
  color: darkgrey;
  font-size: 13px;
  font-style: italic;
}

.table .divider {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}

.table .item-addon {
  background-color: #f6f5f3;
}
</style>
