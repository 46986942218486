import { helpers } from 'vuelidate/lib/validators';

// Custom validator for Vuelidate
export const mustContain = (contains) => helpers.withParams(
        {
            type: 'mustContain',
            required: contains
        },
    value => value.toString().includes(contains)
);

export const mustNotContain = (notContains) => helpers.withParams(
    {
        type: 'mustContain',
        exclude: notContains
    },
    value => !value.toString().includes(notContains)
);
