<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Menu Categories</div>
          <category-action
            v-if="permissions.create"
            id="addCategory"
            key="addCategory"
            :categories="categories"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </category-action>
        </div>
        <div class="card-body">

          <!--list-->
          <data-table
            id="categories"
            :column-map="columnMap"
            :data="categories"
            @actionClicked="handleAction"
          />

          <!--actions-->
          <category-action
            id="editCategory"
            key="editCategory"
            :categories="categories"
            :category="actionSubject.subject"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />


          <delete-action
            :confirm-body="`
                            This will delete the '${actionSubject.subject.name}' category permanently.
                            You can delete a category once it has no items.
                        `"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/menu/categories"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryAction from '../DoActions/CategoryAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import DataTable from '../Utility/DataTable';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';

export default {
  name: "categories-page-content",
  props: {
    categories: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {CategoryAction, DeleteAction, BadgeComp, DataTable},
  data() {
    return {
      columnMap: [
        {data: 'name', title: 'Name'},
        {data: 'parent_id', title: 'Parent', render: this.renderParent},
        {data: 'sort', title: 'Sort'},
        {data: 'status', title: 'Status', render: this.renderBadge},
        {data: 'id', title: 'Actions', render: this.renderAction},
      ],

      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.categories.find(itm => itm.id === id),
        action: action,
      };
    },

    renderBadge(data, type, row) {

      if (data == 1) {
        return `<span class="badge table-badge badge-primary badge-pill">Enabled</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Disabled</span>`
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param data
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(data, type, row) {

      let actions = '';

      if (this.permissions.update) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-edit1"></i>
          </span>
        `;
      }

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      return actions;
    },

    renderParent(data, type, row) {

      if (row.is_parent) {
        return `<span class="badge table-badge badge-primary badge-pill">Parent</span>`;
      }

      const found = this.categories.find(itm => itm?.id === data);
      return (!found || found === -1) ? '' : found.name;
    }
  }
}
</script>

<style>
.table-badge {
  font-size: 12px;
}
</style>
