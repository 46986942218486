<template>
    <div>
        <slot :open-modal="openModal"></slot>

        <modal-comp
            :id="modalId"
            :show="showModal"
            title="Update User Group"
            header-classes="bg-warning text-dark"
            close-btn-classes="text-dark"
            @submit="handleSubmit"
            @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', modalId);
            }"
        >
            <form v-if="showModal">
                <div class="form-group">
                    <label for="userName" class="col-form-label">
                        User:
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="userName"
                        v-model.trim="user.name"
                        autocomplete="off"
                        readonly
                    >
                </div>
                <div class="form-group">
                    <label for="email" class="col-form-label">
                        Email:
                    </label>
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model.trim="user.email"
                        autocomplete="off"
                        readonly
                    >
                </div>
                <div class="form-group">
                    <label for="groups" class="col-form-label">Groups:</label>
                    <span class="text-danger">*</span>
                    <treeselect
                        id="groups"
                        placeholder="Select Groups"
                        v-model="form.select.groups"
                        :options="form.select.options"
                        :multiple="true"
                        :searchable="false"
                        :clearable="false"
                        valueConsistsOf="LEAF_PRIORITY"
                    />
                    <form-error-msg :error="formError.groups" />
                </div>
            </form>

            <template #submit-btn-content>
                <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
              Update user Group
            </template>
        </modal-comp>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import ErrorHelper from '../../../js/helpers/ErrorHelper';
import FormErrorMsg from '../../Utility/FormErrorMsg';
import NotificationHelper from '../../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../../Utility/ModalComp';

export default {
    name: "user-action",
    components: {FormErrorMsg, Treeselect, ModalComp},
    props: {
        show: {
            type: Boolean,
            default: false
        },
        modalId: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        groups: {
            type: [Array,Boolean],
            default: false
        }
    },

    data() {
        return {
            showModal: false,

            form: {
                select: {
                    groups: [],
                    options: []
                },
                submitting: false
            },
            formError: {
                groups: false
            }
        };
    },

    validations: {
        form: {
            select: {
                groups: {
                    required,
                    minLength: minLength(1)
                }
            }
        }
    },

    watch: {
        show:  {
            handler(nv){
              this.showModal = nv;
            },
          immediate : true,
        },
        // error handling
        'form.select.groups': function() {
            this.formError.groups = ErrorHelper.errAll(this.$v, 'form.select.groups');
        }
    },

    mounted() {
        this.prepareSelectOptions();
        this.preSelectOptions();
    },

    methods: {
        openModal() {
            this.showModal = true;
        },

        async handleSubmit() {

            // check if form has any error
            if (this.$v.$invalid) {

                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: 'Invalid Data',
                    body: 'Please fill the form correctly before submitting',
                    type: 'er'
                });

                return false;
            }

            const response = await this.sendData();

            if (response) {

                // show success notification
                NotificationHelper.showSimpleNotification(this.$snotify, {
                    title: 'User Updated',
                    body: `User '${response.data.data.name}'s groups updated successfully`,
                    type: 'su',
                    autoHideDelay: 2,
                    closeCallback: () => window.location.reload()
                });

                // close modal
                this.showModal = false;
            }
        },

        async sendData() {

            this.form.submitting = true;

            const endPoint = `/admin/acl/users/${this.user.id}`;

            const requestData = {
                groups: this.form.select.groups
            };

            try {

                const res = await axios.patch(endPoint, requestData);

                this.form.submitting = false;
                return res;

            } catch (e) {

                this.form.submitting = false;

                // show notification about error
                NotificationHelper.showServerError(this.$snotify, e);

                // map errors to display in form
                ErrorHelper.mapServerError(this.formError, e);

                return false;
            }

        },

        prepareSelectOptions() {

            if (!this.groups) {
                return [];
            }

            const options = [];

            // iterate each group
            this.groups.map(group => {

                // create new option group
                const optionGroup = {
                    id: group.id,
                    label: group.name,
                };

                options.push(optionGroup);
            });

            this.form.select.options =  options;
        },

        preSelectOptions() {

            const groups = this.user.groups;

            if (Array.isArray(groups)) {
                groups.map(({ id }) => this.form.select.groups.push(id));
            } else {
                Object.values(groups).map(groupName => {
                    const foundGroup = this.groups.find(group => group.name === groupName);
                    this.form.select.groups.push(foundGroup.id);
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
