<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
    >
      <form class="row">
        <div :class="`form-group ${isUpdating ? 'col-12' : 'col-7'}`">
          <label for="itemName" class="col-form-label">
            {{ form.createGroup ? 'Group' : 'Item' }} Name:
            <span class="text-danger">*</span>
            <form-input-help>Item or Group name</form-input-help>
          </label>
          <input
            type="text"
            class="form-control"
            id="itemName"
            placeholder="Item item name"
            v-model.trim="form.itemName"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.itemName" />
        </div>
        <div
          v-if="!isUpdating"
          class="form-group col-5"
        >
          <label for="sort" class="col-form-label">
            Create As Group:
            <span class="text-danger">*</span>
            <form-input-help>Enable to create group</form-input-help>
          </label>
          <switch-comp
            :checked="form.createGroup"
            @change="val=>form.createGroup=val"
            enable-text="Creating Group"
            disable-text="Creating Item"
          />
        </div>
        <transition name="fade">
          <div
            v-if="!form.createGroup && !itemIsGroup"
            class="form-group col-12"
          >
            <label for="group" class="col-form-label">
              Select Group:
              <form-input-help>Only groupSelect group if you want to add this item into a group</form-input-help>
            </label>
            <treeselect
              id="group"
              placeholder="Select a group item"
              v-model="form.groupSelect.group"
              :options="form.groupSelect.options"
              :multiple="false"
              :searchable="false"
              :clearable="true"
            />
          </div>
        </transition>
        <div class="form-group col-12"
        >
          <label for="type" class="col-form-label">
            Select Type:
            <span class="text-danger">*</span>
            <form-input-help>Select item type</form-input-help>
          </label>
          <treeselect
            id="type"
            placeholder="Select item type"
            v-model="form.typeSelect.type"
            :options="form.typeSelect.options"
            :multiple="false"
            :searchable="false"
            :clearable="true"
            :disabled="this.typeFieldDisabled"
          />
          <form-error-msg :error="formError.type" />
        </div>
        <div class="form-group col-12">
          <label for="category" class="col-form-label">
            Select Category:
            <span class="text-danger">*</span>
            <form-input-help>Select category that this item belongs to</form-input-help>
          </label>
          <treeselect
            id="category"
            placeholder="Select a group item"
            v-model="form.categorySelect.category"
            :options="form.categorySelect.options"
            :multiple="false"
            :searchable="false"
            :clearable="false"
            :disabled="categoryFieldDisabled"
          />
          <form-error-msg :error="formError.category" />
        </div>
        <transition name="fade">
          <div
            v-if="!form.createGroup && !itemIsGroup"
            class="form-group col-12"
          >
            <label for="addon" class="col-form-label">
              Select Addons:
              <form-input-help>Add addons with this item</form-input-help>
            </label>
            <treeselect
              id="addon"
              placeholder="Select addons"
              v-model="form.addonSelect.addons"
              :options="form.addonSelect.options"
              :multiple="true"
              :searchable="false"
              :clearable="true"
            />
          </div>
        </transition>
        <div class="form-group col-6">
          <label for="price" class="col-form-label">
            Price:
            <span class="text-danger">*</span>
            <form-input-help>Price with decimals</form-input-help>
          </label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            id="price"
            placeholder="Sort position (descending)"
            v-model.trim="form.price"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.price" />
        </div>
        <div class="form-group col-6">
          <label for="sort" class="col-form-label">
            Sort:
            <span class="text-danger">*</span>
            <form-input-help>Works on descending order</form-input-help>
          </label>
          <input
            type="number"
            class="form-control"
            id="sort"
            placeholder="Sort position (descending)"
            v-model.trim="form.sort"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.sort" />
        </div>
        <div class="form-group col-7">
          <label for="description" class="col-form-label">
            Description:
            <form-input-help>Works on descending order</form-input-help>
          </label>
          <textarea
            class="form-control"
            id="description"
            placeholder="Item description"
            v-model.trim="form.description"
            required
          ></textarea>
          <form-error-msg :error="formError.description" />
        </div>
        <div class="form-group col-5">
          <label for="sort" class="col-form-label">
            Status:
            <form-input-help>Disable to hide {{ form.createGroup ? 'group' : 'item' }}</form-input-help>
          </label>
          <switch-comp
            :checked="form.status"
            @change="val=>form.status=val"
            enable-text="Enabled"
            disable-text="Disabled"
          />
        </div>
      </form>

      <template #submit-btn-content>
        <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
        {{ modalSubmitBtnText }}
      </template>
    </modal-comp>
  </div>
</template>

<script>
import { required, minLength, maxLength, numeric, minValue, decimal } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import SwitchComp from '../Utility/SwitchComp';
import { mustContain } from '../../js/CustomValidators';

export default {
  name: "item-action",
  components: {FormErrorMsg, Treeselect, ModalComp, FormInputHelp, SwitchComp},
  props: {
    id: {
      type: String,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    },
    addons: {
      type: Array,
      required: true
    },
    item: {
      type: [Object, Boolean]
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'New Group or Item',
        headerClasses: '',
        closeBtnClasses: '',
      },
      categoryFieldDisabled: false,
      typeFieldDisabled: false,

      form: {
        itemName: '',
        createGroup: false,
        price: 0.01,
        sort: 0,
        status: true,
        description: '',
        groupSelect: {
          group: null,
          options: []
        },
        typeSelect: {
          type: null,
          options: []
        },
        categorySelect: {
          category: null,
          options: []
        },
        addonSelect: {
          addons: [],
          options: []
        },
        submitting: false
      },
      formError: {
        itemName: false,
        category: false,
        type: false,
        price: false,
        sort: false,
        description: false
      }
    };
  },

  validations: {
    form: {
      itemName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(120)
      },
      categorySelect: {
        category: {
          required
        }
      },
      typeSelect: {
        type: {
          required
        }
      },
      price: {
        required,
        decimal,
        mustContain: mustContain('.'),
        minValue: minValue(0.00)
      },
      sort: {
        required,
        numeric,
        minValue: minValue(0)
      },
      description: {
        maxLength: maxLength(300)
      }
    }
  },

  computed: {
    modalSubmitBtnText() {
      return this.isUpdating
        ? 'Save Changes'
        : `Add ${this.form.createGroup ? 'Group' : 'Item'}`
        ;
    },
    isUpdating() {
      return !!this.item;
    },
    itemIsGroup() {
      return !!(this.isUpdating && this.item.is_group);
    }
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
    item: {
      immediate: true,
      handler() {
        this.populateForUpdate();
      }
    },
    'form.createGroup': function (nv) {
      if (nv) {
        this.form.groupSelect.group = null;
      }
    },
    'form.groupSelect.group': function (nv) {

      // when group is selected
      // disable category selection
      this.categoryFieldDisabled = !!nv;

      // disable type selection
      this.typeFieldDisabled = !!nv;

      if (nv) {
        const selectedGroupCategory = this.items.find(itm => itm.id === nv);

        // select groups category
        this.form.categorySelect.category = selectedGroupCategory.category.id;

        // select groups type
        this.form.typeSelect.type = selectedGroupCategory.type;
      }
    },

    // error handling
    'form.itemName': function () {
      this.formError.itemName = ErrorHelper.errAll(this.$v, 'form.itemName');
    },
    'form.price': function () {
      this.formError.price = ErrorHelper.errAll(this.$v, 'form.price');
    },
    'form.sort': function () {
      this.formError.sort = ErrorHelper.errAll(this.$v, 'form.sort');
    },
    'form.description': function () {
      this.formError.description = ErrorHelper.errAll(this.$v, 'form.description');
    },
    'form.categorySelect.category': function () {
      this.formError.category = ErrorHelper.errAll(this.$v, 'form.categorySelect.category');
    },
    'form.typeSelect.type': function () {
      this.formError.type = ErrorHelper.errAll(this.$v, 'form.typeSelect.type');
    }
  },

  mounted() {
    this.makeGroupOptions();
    this.makeTypeOptions();
    this.makeAddonOptions();
    this.makeCategoryOptions();
    // this.populateForUpdate();
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    async handleSubmit() {

      // check if form has any error
      if (this.$v.$invalid) {

        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });

        return false;
      }

      const response = await this.sendData();

      if (response) {

        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: `${this.itemIsGroup ? 'Group' : 'Item'} ${this.isUpdating ? 'Updated' : 'Created'}`,
          body: `'${response.data.name}' ${this.itemIsGroup ? 'group' : 'item'} ${this.isUpdating ? 'updated' : 'created'} successfully`,
          type: 'su',
          autoHideDelay: 2,
          closeCallback: () => window.location.reload()
        });

        // close modal
        this.showModal = false;
      }
    },

    async sendData() {

      this.form.submitting = true;

      const endPoint = (this.isUpdating)
        ? `/admin/menu/items/${this.item.id}`
        : `/admin/menu/items`
      ;
      const method = (this.item) ? 'patch' : 'post';

      const requestData = {
        itemName: this.form.itemName,
        isGroup: this.form.createGroup,
        // add only if its not a group
        group: this.form.createGroup ? null : this.form.groupSelect.group,
        addons: this.form.createGroup ? [] : this.form.addonSelect.addons,

        category: this.form.categorySelect.category,
        type: this.form.typeSelect.type,
        price: parseFloat(this.form.price).toFixed(2),
        sort: parseInt(this.form.sort),
        description: this.form.description,
        status: this.form.status ? '1' : '0'
      };

      try {

        const res = await axios[method](endPoint, requestData);

        this.form.submitting = false;
        return res;

      } catch (e) {

        this.form.submitting = false;

        // show notification about error
        NotificationHelper.showServerError(this.$snotify, e);

        // map errors to display in form
        ErrorHelper.mapServerError(this.formError, e);

        return false;
      }

    },

    makeGroupOptions() {

      this.items.map(({id, name, is_group}) => {

        // exclude self && sub items
        if (is_group) {
          this.form.groupSelect.options.push(
            {
              id,
              label: name
            }
          );
        }

      });
    },

    makeTypeOptions() {

      this.itemTypes.map(type => {
        this.form.typeSelect.options.push(
          {
            id: type,
            label: type
          }
        );
      });
    },

    makeAddonOptions() {

      this.addons.map(({id, name}) => {
        this.form.addonSelect.options.push(
          {
            id,
            label: name
          }
        );
      });
    },

    makeCategoryOptions() {

      this.categories.map(({id, name}) => {

        // exclude self && sub items
        this.form.categorySelect.options.push(
          {
            id,
            label: name
          }
        );

      });
    },

    populateForUpdate() {

      // only populate for updating
      if (!this.isUpdating) return false;

      // customize modal
      this.modal = {
        title: 'Update Group Or Item',
        headerClasses: 'bg-warning text-dark',
        closeBtnClasses: 'text-dark',
      };

      // populate values
      this.form.createGroup = this.item.is_group;
      this.form.itemName = this.item.name;
      this.form.groupSelect.group = this.item?.group?.id;
      this.form.typeSelect.type = this.item?.type;
      this.form.categorySelect.category = this.item?.category?.id;
      this.form.price = this.item.price?.toFixed(2);
      this.form.sort = this.item.sort;
      this.form.description = this.item.description;
      this.form.status = (this.item.status == 1);

      if (this.item.addons) {
        this.form.addonSelect.addons = this.item.addons.map(({id}) => id);
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
