import moment from 'moment';
import { TIME_SELECTS_FORMAT } from './Constants';

export const formatModalId = (id, prefix='mid') => `${prefix}${id}`;

export const formatRolePermissions = (permissions) => {
    const newPerms = [];

    for (let perm in permissions) {

        // @TODO: refactor this when plugin cache bug fixed
        const notArr = perm.includes('-');
        const value = permissions[perm];

        if (notArr) {
            newPerms.push({
                name: perm,
                slug: Object.keys(value)
            });
        } else {
            newPerms.push({
                name: value.name,
                slug: Object.keys(value.slug)
            });
        }
    }

    return newPerms;
};

export const getPermissionSubject = permissionName => {

    if (typeof permissionName !== 'string') throw new Error(`Can't get permission subject while permission name is not string`);

    const separator = '-';
    return permissionName.substr(0, permissionName.indexOf(separator));
};

export const formatDate = (timeString, format='Do MMM, YYYY') => {
    return moment(timeString).format(format);
};

export const formatTimePickerCompatibleTime = (timeString) => {
    const timeStr = `2020-12-12 ${timeString}`;
    return formatDate(timeStr, TIME_SELECTS_FORMAT);
};


export const userAvatarChar = (userName) => {

  if (!userName.length) return "";


  let nameSplit = userName.split(' ');

  let chars = '';
  if (nameSplit.length === 1) {
    chars += nameSplit[0][0]; // first char
    chars += nameSplit[nameSplit.length - 1][1]; // last char
    return chars;
  }

  chars += nameSplit[0][0]; // first char of first element
  chars += nameSplit[nameSplit.length - 1][0]; // first char of last element

  return chars;

}
