<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Menu Addons</div>
          <addon-action
            v-if="permissions.create"
            id="addAddon"
            :types="types"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </addon-action>
        </div>
        <div class="card-body">

          <!--Listing-->
          <data-table
            id="categories"
            :column-map="columnMap"
            :data="addons"
            @actionClicked="handleAction"
          />

          <!--Actions-->
          <addon-action
            id="editAddon"
            :addon="actionSubject.subject"
            :show="actionStatus.edit"
            :types="types"
            @user-action-hide="()=>this.actionStatus.edit=false"
          />

          <delete-action
            :confirm-body="`
                            '${actionSubject.subject.name}' will be deleted ${actionSubject.subject.is_group
                                ? '& all addons under this group will leave the group'
                                :' permanently'}.
                        `"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/menu/addons"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddonAction from '../DoActions/AddonAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import DataTable from '../Utility/DataTable';
import { LIST_DELETE_ACTION_TYPE, LIST_EDIT_ACTION_TYPE } from '../../js/helpers/Constants';

export default {
  name: "addons-page-content",
  props: {
    addons: {
      type: Array,
      required: true
    },
    types: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {AddonAction, DeleteAction, BadgeComp, DataTable},
  data() {
    return {
      columnMap: [
        {data: 'name', title: 'Name'},
        {data: 'price', title: 'Price', render: price => price.toFixed(2)},
        {data: 'sort', title: 'Sort'},
        {data: 'type', title: 'Type', render: this.renderType},
        {data: 'status', title: 'Status', render: this.renderStatus},
        {data: 'id', title: 'Actions', render: this.renderAction},
      ],

      actionStatus: {
        edit: false,
        delete: false
      },
      actionSubject: {
        subject: false,
        action: false
      }
    }
  },
  watch: {
    actionSubject({action, subject}) {

      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
    }
  },
  methods: {
    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.addons.find(itm => itm.id === id),
        action: action,
      };
    },

    renderStatus(status) {

      if (status == 1) {
        return `<span class="badge table-badge badge-primary badge-pill">Enabled</span>`
      }

      return `<span class="badge table-badge badge-dark badge-pill">Disabled</span>`
    },

    renderType(type) {

      if (type == 'item') {
        return `<span class="badge table-badge badge-primary badge-pill">Item</span>`
      }

      return `<span class="badge table-badge badge-secondary badge-pill text-capitalize">${type}</span>`
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param id
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(id, type, row) {

      let actions = '';

      if (this.permissions.update) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${id}"
          >
              <i class="icon-edit1"></i>
          </span>
        `;
      }

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${id}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      return actions;
    },

    renderParent(data) {
      const found = this.categories.find(itm => itm?.id === data);
      return (!found || found === -1) ? '' : found.name;
    }
  }
}
</script>

<style scoped>

</style>
