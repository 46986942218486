<template>
    <span
      id="printContainer"
      @click="print"
    >
        <slot></slot>
    </span>
</template>

<script>

export default {
  name: "print-comp",
  props: {
    print: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Object, null],
      default: null
    }
  },

  data() {
    return {
      startPrint: false
    }
  },

  watch: {
    startPrint(nv) {
      if (nv) {
        print();
      }
    },

    print(nv) {
      this.startPrint = nv;
    }
  },

  methods: {

    print() {
      this.$htmlToPaper('printContainer', this.options, () => {
        this.$emit('printComplete', true);
      });
    }

  }
}
</script>

<style scoped>
</style>
