<template>
  <div class="row">

    <!--Setting-->
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Setting Entries</div>
          <div class="page-action-btn-container">
            <button
              class="d-flex btn btn-sm btn-outline-secondary p-2 mr-2"
              @click="getSettingDifference"
            >
              <Spinner
                v-if="settingDebugInProgress"
                class="mr-1"
                type="dar"
                size="20"
              />
              <i v-else class="icon-bug_report fs20"></i>Debug Keys
            </button>
            <button
              class="d-flex btn btn-sm btn-outline-secondary p-2 mr-2"
              @click="sendClearCacheCommand"
            >
              <Spinner
                v-if="clearCacheInProgress"
                class="mr-1"
                type="dar"
                size="20"
              />
              <i v-else class="icon-layers1 fs20"></i>Clear Cache
            </button>
            <setting-action
              v-if="permissions.create"
              id="addSetting"
              :groups="settingGroups"
            >
              <template #default="sProps">
                <button
                  class="btn btn-sm btn-primary p-2"
                  @click="sProps.openModal"
                >
                  <i class="icon-add fs20"></i>Add New
                </button>
              </template>
            </setting-action>
          </div>
        </div>
        <div class="card-body row">

          <div
            class="`col-12"
            id="setting-search-group"
            :style="isMobile? style.searchBar.mobile : style.searchBar.desktop"
          >
            <div class="form-group">
              <div class="input-group">

                <treeselect
                  id="settingKey"
                  placeholder="Select Setting"
                  v-model="selectedKeyId"
                  :options="settingOptions"
                  :multiple="false"
                  :searchable="true"
                  :clearable="false"
                  :disableBranchNodes="true"
                  :showCount="true"
                />

                <div class="input-group-append text-nowrap">
                  <setting-action
                    v-if="permissions.update"
                    id="editSetting"
                    :groups="settingGroups"
                    :setting="selectedSetting"
                  >
                    <template #default="sProps">
                      <button
                        class="btn btn-dark h-100"
                        @click="sProps.openModal"
                        :disabled="!selectedSetting"
                      >
                        <i class="icon-edit"></i> Edit Selected
                      </button>
                    </template>
                  </setting-action>
                  <delete-action
                    v-if="permissions.delete"
                    endpoint="/admin/settings"
                    :url-arguments="selectedSetting.id"
                    :confirmation="true"
                    :confirm-body="`This will delete the '${selectedSetting.key}' setting permanently.`"
                  >
                    <template #default="{click}">
                      <button
                        class="btn btn-secondary h-100"
                        @click.prevent="click"
                        :disabled="showDelete"
                      >
                        <i class="icon-delete"></i>
                        Delete
                      </button>
                    </template>
                  </delete-action>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p2">
            <div class="table-responsive mt-5">
              <table v-if="selectedSetting" class="table">
                <tbody>
                <tr>
                  <td class="key"> Value
                    <i title="Inspect value"
                       :class="`inspect-value icon-arrow-with-circle-${ inspectValue ? 'left' : 'right'}`"
                       @click="inspectValue = !inspectValue"
                    />

                  </td>
                  <td v-if="!inspectValue" class="value main">
                    <div v-if="selectedSetting.value_type === 'file'">
                      <img v-if="!isVideo" class="img-fluid img-thumbnail img-90"
                           :src="'/storage/settings/'+encodeURI(selectedSetting.value)" alt="">

                      <video v-else controls class="embed-responsive img-fluid img-thumbnail admin-video">
                        <source :src="'/storage/settings/'+encodeURI(selectedSetting.value)" type="video/mp4">
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                    </div>

                    <p v-else-if="selectedSetting.value_type === 'text'">{{selectedSetting.value}}</p>

                    <div v-else-if="selectedSetting.value_type === 'bool'">
                      <switch-comp :checked="selectedSetting.value" disabled></switch-comp>
                    </div>

                  </td>
                  <td v-else class="value main">
                    <textarea class="form-control">{{ selectedSetting.value }}</textarea>
                  </td>
                </tr>
                <tr>
                  <td class="key">Admin Only</td>
                  <td class="value">
                    <switch-comp :checked="selectedSetting.admin_only" disabled></switch-comp>
                  </td>
                </tr>
                <tr>
                  <td class="key">Deletable</td>
                  <td class="value">
                    <switch-comp :checked="selectedSetting.is_deletable" disabled></switch-comp>
                  </td>
                </tr>
                <tr>
                  <td class="key">Group</td>
                  <td class="value">{{ selectedSetting.group }}</td>
                </tr>
                <tr>
                  <td class="key">Options</td>
                  <td class="value">{{ selectedSetting.options ? selectedSetting.options : "No Option Defined" }}</td>
                </tr>
                <tr>
                  <td class="key">Value Type</td>
                  <td class="value">{{ selectedSetting.value_type }}</td>
                </tr>
                <tr>
                  <td class="key">Created</td>
                  <td class="value">{{ formatDate(selectedSetting.created_at) }}</td>
                </tr>
                <tr>
                  <td class="key">Updated</td>
                  <td
                    class="value"
                    v-if="selectedSetting.created_at === selectedSetting.updated_at"
                  >
                    Never updated
                  </td>
                  <td
                    class="value"
                    v-else
                  >
                    {{ formatDate(selectedSetting.updated_at) }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div
                v-else
                class="lead text-center"
              >
                Select a setting to check its value
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--Debug-->
    <div class="col-12" v-if="debugData">
      <div class="card mt-2">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Setting Debugger</div>
        </div>
        <div class="card-body row">

          <!--Missing in DB-->
          <div class="col-sm-12 col-md-6 p2">
            <div class="table-responsive mt-5">
              <table
                class="table"
                v-if="debugData.definition.length"
              >
                <thead>
                  <tr>
                    <th>Missing in DB</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="settingKey in debugData.definition"
                    :key="settingKey"
                  >
                    <td>{{ settingKey }}</td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="lead text-center"
              >
                No Keys Missing in DB
              </div>
            </div>
          </div>

          <!--Missing in Definition-->
          <div class="col-sm-12 col-md-6 p2">
            <div class="table-responsive mt-5">
              <table
                class="table"
                v-if="debugData.db.length"
              >
                <thead>
                  <tr>
                    <th>Missing in Definition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="settingKey in debugData.db"
                    :key="settingKey"
                  >
                    <td>{{ settingKey }}</td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="lead text-center"
              >
                No Keys Missing in Definition
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { formatDate, formatModalId } from '../../js/helpers/Helper';
import SettingAction from '../DoActions/SettingAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import Treeselect from '@riophae/vue-treeselect';
import { isMobileOnly } from 'mobile-device-detect';
import SwitchComp from "../Utility/SwitchComp";
import Spinner from '../Utility/Spinner';
import NotificationHelper from '../../js/helpers/NotificationHelper';

export default {
  name: "settings-page-content",
  props: {
    settings: {
      type: [Object, Array],
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {Spinner, SwitchComp, SettingAction, DeleteAction, BadgeComp, Treeselect},
  data() {
    return {
      settingDebugInProgress: false,
      debugData: null,

      clearCacheInProgress: false,
      modals: {},
      selectedKeyId: null,
      settingOptions: [],
      style: {
        searchBar: {
          mobile: {
            padding: '18px 2px 4px 2px'
          },
          desktop: {
            padding: '20px 15px 8px 15px'
          }
        }
      },

      selectedSetting: false,
      inspectValue: false
    }
  },
  computed: {
    isVideo(){

      if(this.selectedSetting.value_type !== 'file'){
        return false
      }

      let videoFormat = ['mp4']
      let value = this.selectedSetting.value.split('.').slice(-1).pop();
      return videoFormat.includes(value);
    },
    isMobile() {
      return isMobileOnly;
    },
    settingGroups() {
      return Object.keys(this.settings);
    },
    showDelete(){
      if(!this.selectedSetting){
        return true;
      }else{
        return !this.selectedSetting.is_deletable;
      }

    },
  },
  watch: {
    selectedKeyId(nv) {
      this.selectedSetting = Object
        .values(this.settings)
        .flat()
        .find(({id}) => id === nv)
      ;
    },
    selectedSetting : {
      handler(e){
        if(this.selectedSetting.value_type === 'bool'){
          if(!this.selectedSetting){
            //this.selectedSetting.value = this.selectedSetting.value === 0
          }

        }
      }
    }
  },
  mounted() {
    this.makeSettingOptions();
  },
  methods: {
    showEditModal(categoryId) {
      const mid = this.getMid(categoryId);
      this.modals[mid] = true;
    },

    hideEditModal(categoryId) {
      const mid = this.getMid(categoryId);
      this.modals[mid] = false;
    },

    getMid(id) { // model id
      return formatModalId(id);
    },

    makeSettingOptions() {
      const options = [];

      for (let setting in this.settings) {
        const groupSettings = this.settings[setting];
        const singleOption = {
          id: setting,
          label: setting
        };

        singleOption.children = groupSettings.map(({id, key}) => ({id, label: key}));
        options.push(singleOption);
      }

      this.settingOptions = options;
    },

    formatDate(timeString) {
      return formatDate(timeString, 'Do MMM, YYYY @hh:mm A');
    },

    sendClearCacheCommand() {
      this.clearCacheInProgress = true;

      const endpoint = `/admin/settings/clear-cache`;

      axios
        .get(endpoint)
        .then(res => NotificationHelper.showSimpleNotification(this.$snotify, {
          title: res.data.message,
          body: 'Cached data has been cleared',
          type: 'su'
        }))
        .catch(err => NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Cache clear failed',
          body: 'System has failed to clear the cache',
          type: 'su'
        }))
        .finally(() => this.clearCacheInProgress = false)
      ;

    },

    getSettingDifference() {
      this.settingDebugInProgress = true;

      const endpoint = `/admin/settings/debug`;

      axios
        .get(endpoint)
        .then(res => {

          this.debugData = res.data.data;

          NotificationHelper.showSimpleNotification(this.$snotify, {
            title: res.data.message,
            body: 'Setting debug complete, Check following section for details',
            type: 'su'
          });
        })
        .catch(err => NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Setting debug failed',
          body: 'System has failed to get setting differences',
          type: 'er'
        }))
        .finally(() => this.settingDebugInProgress = false)
      ;
    }
  }
}
</script>

<style lang="scss" scoped>
#setting-search-group {
  background-color: #f3f3f3;

  .input-group {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 600px) {
  #setting-search-group .input-group {
    flex-wrap: nowrap;
  }
}

.key, .value {
  font-size: 18px;
}

.key {
  background-color: #f3f3f3;
}

.value:not(.main) {
  color: #9e9e9e;
}

.inspect-value {
  color: #007ae1;
  cursor: pointer;
}

.admin-video{
  width: 300px;
  height: auto
}
</style>
