import _ from 'lodash';

export default class ErrorHelper {

    static errAll(validator, key) {

        const validatorItem = getItemFromObject(validator, key);

        let errors = Object.keys(validatorItem.data.$params).map(itm => {

            switch (itm) {
                case 'required':
                    return ErrorHelper.errRequired(validatorItem, 'required');

                case 'between':
                    return ErrorHelper.errBetween(validatorItem, 'between');

                case 'minLength':
                    return ErrorHelper.errMinLength(validatorItem, 'minLength');

                case 'maxLength':
                    return ErrorHelper.errMaxLength(validatorItem, 'maxLength');

                case 'minValue':
                    return ErrorHelper.errMinValue(validatorItem, 'minValue');

                case 'maxValue':
                    return ErrorHelper.errMaxValue(validatorItem, 'maxValue');

                case 'mustContain':
                    return ErrorHelper.errMustContain(validatorItem, 'mustContain');

                case 'mustNotContain':
                    return ErrorHelper.errMustNotContain(validatorItem, 'mustNotContain');

                case 'numeric':
                    return ErrorHelper.errNumeric(validatorItem, 'numeric');

                default:
                    return false;
            }

        }).filter(itm => itm);

        return (errors.length) ? errors[0] : false;
    }

    static errRequired(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        return !data[ruleName]
            ? `"${keyName}" is required`
            : false
        ;
    }

    static errBetween(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {max, min} = data.$params[ruleName];
        const suffix = (typeof data.$model === 'string') ? 'characters' : '';

        return !data[ruleName]
            ? `"${keyName}" must be between ${min} and ${max} ${suffix}`
            : false
        ;
    }

    static errMinLength(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {min} = data.$params[ruleName];
        const suffix = typeof data.$model === 'string' ? 'characters' : '';

        return !data[ruleName]
            ? `"${keyName}" must be at least ${min} ${suffix}`
            : false
        ;
    }

    static errMaxLength(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {max} = data.$params[ruleName];
        const suffix = (typeof data.$model === 'string') ? 'characters' : '';

        return !data[ruleName]
            ? `"${keyName}" must not exceed ${max} ${suffix}`
            : false
        ;
    }

    static errMinValue(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {min} = data.$params[ruleName];

        return !data[ruleName]
            ? `"${keyName}" must be at least ${min}`
            : false
        ;
    }

    static errMaxValue(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {max} = data.$params[ruleName];

        return !data[ruleName]
            ? `"${keyName}" must be less than or equal to ${max}`
            : false
        ;
    }

    static errMustContain(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {required} = data.$params[ruleName];

        return !data[ruleName]
            ? `"${keyName}" must contain '${required}'`
            : false
        ;
    }

    static errMustNotContain(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {exclude} = data.$params[ruleName];

        return !data[ruleName]
            ? `"${keyName}" must not contain '${exclude}' ${(exclude === ' ') ? '(space)':''}`
            : false
        ;
    }

    static errNumeric(validatorItem, ruleName) {

        const {keyName, data} = validatorItem;

        // if item not exist
        if (!keyName) {
            return false;
        }

        const {type} = data.$params[ruleName];

        return !data[ruleName]
            ? `"${keyName}" must be '${type}'`
            : false
        ;
    }

    /**
     * Maps server returned error with component
     * @param errObj    Components (form)error object
     * @param errResponse Servers error response
     * @param fuzzy     Fuzzy matches errorObj keys. like: 'days.0' will match 'days'
     */
    static mapServerError(errObj, errResponse, fuzzy=false) {

       const errors = errResponse?.response?.data?.errors;

        if (!errors) return false;

        Object.keys(errors).map(key=> {

            // if item not found
            if (errObj[key] === undefined && !fuzzy) return false;

            if (!fuzzy) {
                // assign 1st error message to error item
                errObj[key] = errors[key][0];

            } else {
                // find matching error object index
                const errorObjKey = Object.keys(errObj).find(ky => key.includes(ky));

                // if key found
                if (errorObjKey !== -1) {
                    errObj[errorObjKey] = errors[key][0];
                }
            }
        });

    }
}


export function getItemFromObject(validator, key) {

    let result;

    // if nested key asked
    if (key.includes('.')) {

        // keys array
        key = key.split('.');

        // reduce to single value
        result = key.reduce((acc, itm) => {

            const item = acc.data[itm];

            // don't continue in false or undefined
            if (!item) {
                return false;
            }

            // save with key name
            return {
                keyName: itm,
                data: item
            };


        }, {keyName:'', data:validator});

    } else {
        result = {
            keyName: key,
            data: validator[key]
        };
    }

    return result;
}

export function hasError(obj) {
    return Object.keys(obj)
        .map(itm => obj[itm])
        .filter(itm => itm)
        .length
    ;
}

export function isAllEmpty(obj) {

    const keys = Object.keys(obj);
    const emptyCount = keys
        .map(itm => obj[itm])
        .filter(itm => _.isEmpty(itm))
        .length
    ;

    return (keys.length === emptyCount);
}
