import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import Vuelidate from 'vuelidate';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// window.Vue = require('vue').default;

window.Vue = Vue;

Vue.use(Vuelidate);
Vue.use(Snotify, {
    position: SnotifyPosition.rightTop,
    toast: {
        titleMaxLength: 30,
        bodyMaxLength: 500
    }
});

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'http://localhost:8000/css/app.css'
    ]
}

Vue.use(VueHtmlToPaper, options);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('TopBreadCrumb', require('../components/PageElements/TopBreadCrumb').default);
Vue.component('PermissionBadge', require('../components/Utility/PermissionBadge').default);
// Vue.component('modal-comp', require('../components/Utility/ModalComp').default);
// Vue.component('test-comp', require('../components/test-comp').default);

Vue.component('dashboard-page-content', require('../components/PageSpecific/DashboardPageContent').default);
Vue.component('groups-page-content', require('../components/PageSpecific/GroupsPageContent').default);
Vue.component('users-page-content', require('../components/PageSpecific/UsersPageContent').default);
Vue.component('permissions-page-content', require('../components/PageSpecific/PermissionsPageContent').default);
Vue.component('categories-page-content', require('../components/PageSpecific/CategoriesPageContent').default);
Vue.component('items-page-content', require('../components/PageSpecific/ItemsPageContent').default);
Vue.component('addons-page-content', require('../components/PageSpecific/AddonsPageContent').default);
Vue.component('settings-page-content', require('../components/PageSpecific/SettingsPageContent').default);
Vue.component('orders-page-content', require('../components/PageSpecific/OrdersPageContent').default);
Vue.component('openings-page-content', require('../components/PageSpecific/OpeningsPageContent').default);
Vue.component('offers-page-content', require('../components/PageSpecific/OffersPageContent').default);
Vue.component('reservation-page-content', require('../components/PageSpecific/ReservationPageContent').default);
Vue.component('delivery-charge-page-content', require('../components/PageSpecific/DeliveryChargePageContent').default);
Vue.component('customers-page-content', require('../components/PageSpecific/CustomersPageContent').default);
Vue.component('seo-page-content', require('../components/PageSpecific/SEOPageContent').default);
Vue.component('user-avatar', require('../components/Utility/UserAvatar').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
