<template>
  <div class="">

    <slot :play="startPlay"></slot>

    <audio
      ref="sound_in_sound_comp"
      :controls="show"
      @canplay="handleReady"
      @ended="handleEnd"
    >
      <source
        :src="filePath"
        type="audio/mpeg"
      />
      <span>
        Your browser does not support the audio element.
      </span>
    </audio>
  </div>
</template>

<script>
export default {
  name: "sound-comp",
  props: {
    fileName: {
      type: String,
      required: true
    },
    play: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    },
    loop: {
      type:Boolean,
      default: false
    },
    loopInterval: { // in ms
      type: Number,
      default: 1000
    },
    autoplay: {
      type:Boolean,
      default: false
    }
  },

  data() {
    return {
      player: {},
      filePath: ''
    }
  },

  watch: {
    enabled: function(nv) {
      // if disabling sound
      if (!nv) {
        this.stop();
      }
    },
    play: function (nv) {
      if (this.enabled && nv) {
        this.startPlay();
      }
    }
  },

  mounted() {
    this.player = this.$refs.sound_in_sound_comp;
    this.setSoundBasePath();
  },

  methods: {
    handleReady() {

      // autoPlay
      if (this.autoplay) {
        this.startPlay();
      }

      this.$emit('ready', true);

    },

    handleEnd() {

      if (this.loop) {
        setTimeout(() => {
          this.startPlay();
        }, this.loopInterval);
      }

      this.$emit('ended', true);
    },

    startPlay() {
      if (this.enabled) {
        this.player.play();
      }
    },

    stop() {
      this.player.pause();
      this.player.currentTime = 0;
    },

    setSoundBasePath() {
      this.filePath = `${window.location.origin}/storage/sounds/${this.fileName}`;
    }
  }
}
</script>

<style scoped>

</style>
