<template>
  <div>
    <slot :open-modal="openModal"></slot>
    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
    >
      <form class="row">
        <div class="form-group col-12">
          <label for="settingKey" class="col-form-label">
            Setting Name:
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="settingKey"
            placeholder="Setting name"
            v-model.trim="form.name"
            autocomplete="off"
            spellcheck="false"
            required
            :readonly="readOnly"
          >
          <div class="text-right">
            <badge-comp type="dar">{{ keyLength ? keyLength : 0 }}/{{ maxAllowed.name }}</badge-comp>
          </div>
          <form-error-msg :error="formError.name"/>
        </div>

        <div class="form-group col-12">
          <label for="settingGroup" class="col-form-label">
            Setting Group:
            <span class="text-danger">*</span>
          </label>
          <div class="input-group" id="groupAddEditGroup">
            <treeselect
              v-if="!addNewGroup"
              placeholder="Select Setting"
              v-model="form.group"
              :options="form.groupOptions"
              :multiple="false"
              :searchable="false"
              :clearable="false"
              :disableBranchNodes="true"
            />

            <input
              v-else
              type="text"
              class="form-control"
              id="settingGroup"
              placeholder="Group name"
              v-model.trim="form.group"
              autocomplete="off"
              spellcheck="false"
            />

            <div class="input-group-append text-nowrap">
              <button
                type="button"
                :class="`btn btn-${addNewGroup ? 'secondary' : 'primary'}`"
                @click="addNewGroup = !addNewGroup"
              >
                {{ addNewGroup ? 'Cancel' : 'New' }}
              </button>
            </div>
          </div>
          <form-error-msg :error="formError.group"/>
        </div>

        <div class="form-group col-12" v-if="!readOnly">
          <label class="col-form-label">Admin Only</label>
          <switch-comp
            :checked="form.adminOnly"
            enable-text="Enabled"
            disable-text="Disabled"
            @change="val=>form.adminOnly = val"
          />
        </div>
        <div class="form-group col-12" v-if="!readOnly">
          <label class="col-form-label">Deletable</label>
          <switch-comp
            :checked="form.isDeletable"
            enable-text="Enabled"
            disable-text="Disabled"
            @change="val=>form.isDeletable = val"
          />
        </div>

        <div class="form-group col-12" v-if="!isUpdating">
          <label class="col-form-label">Select Value Type</label>
          <v-select
            :clearable="false"
            v-model="form.selectedValueType"
            :options="form.value_type"
            :reduce="item => item.slug"
            placeholder="Select Value Type"
          >
          </v-select>
          <form-error-msg :error="formError.value_type"/>
        </div>
        <div class="form-group col-12" v-if="form.selectedValueType">
          <label for="settingValue" class="col-form-label">
            Value: <span class="text-danger">*</span>
            <span class="text-info small" v-if="fieldToShow === 'file'"> Max file Size 1.5 MB </span>
          </label>
          <div v-if="fieldToShow === 'text'">
            <div>
              <textarea
                v-if="!form.options"
                class="form-control"
                id="settingValue"
                placeholder="Setting value"
                v-model="form.value"
                required
              ></textarea>
              <p v-else>
                <v-select :clearable="false" v-model="form.dayOff" :options="form.options" placeholder="Your Off Day"></v-select>
              </p>
            </div>
          </div>

          <div v-else-if="fieldToShow === 'bool'">
            <switch-comp
              :checked="form.value"
              enable-text="Enabled"
              disable-text="Disabled"
              @change="val=>form.value = val"
            />
          </div>

          <div class="form-group" v-else>

            <div class="custom-file">

              <div class="input-group">
                <input @click="triggerClick('uploadMedia')" type="text" class="form-control" placeholder="upload Image"
                       aria-label="Recipient's username" aria-describedby="button-addon2">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="button" id="button-addon2" @click="triggerClick('uploadMedia')">
                    <input @change="handleFileUpload()" type="file" accept=".jpg,.jpeg,.png,.mp4" class="d-none" ref="uploadMedia">
                    Choose File
                  </button>
                </div>
              </div>

            </div>
            <div class="preview mt-2">
              <div class="card">
                <div v-if="isVideo">
                  <video controls class="embed-responsive">
                    <source
                      v-if="form.selectedValueType === 'file' && isUpdating === true"
                      :src="showMedia" type="video/mp4">
                    <source
                      v-if="form.selectedValueType === 'file' && isUpdating === false"
                      :src="userFile" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </div>
                <div v-else class="card-img-top user-uploaded-image">
                  <img class="img-fluid user-image"
                       v-if="form.selectedValueType === 'file' && isUpdating === true"
                       :src="showMedia" alt="">
                  <img v-if="form.selectedValueType === 'file' && isUpdating === false"
                       class="card-img-top img-fluid user-image" :src="userFile" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <badge-comp v-if="fieldToShow === 'text'" type="dar">{{ valueLength ? valueLength : 0 }}/{{
                maxAllowed.value
              }}
            </badge-comp>
          </div>
          <form-error-msg :error="formError.value"/>
        </div>
      </form>

      <template #submit-btn-content>
        <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
        {{ modalSubmitBtnText }}
      </template>
    </modal-comp>
  </div>
</template>

<script>
import VueDropify from 'vue-dropify';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {maxLength, minLength, required} from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import BadgeComp from '../Utility/BadgeComp';
import SwitchComp from "../Utility/SwitchComp";
export default {
  name: "setting-action",
  components: {SwitchComp, FormErrorMsg, Treeselect, ModalComp, BadgeComp, 'v-select': vSelect, dropify: VueDropify},
  props: {
    id: {
      type: String,
      required: true
    },
    setting: {
      type: [Object, Boolean],
      default: false
    },
    groups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      uploadedFile: "",
      userFile: "",
      showModal: false,
      modal: {
        title: 'New Setting Item',
        headerClasses: '',
        closeBtnClasses: '',
      },
      form: {
        name: '',
        value: '',
        group: 'app',
        groupOptions: [],
        submitting: false,
        options: null,
        dayOff : '',
        adminOnly : false,
        isDeletable : false,
        value_type: [
          {
            label: "Text",
            slug: "text"
          },
          {
            label: "Boolean",
            slug: "bool"
          },
          {
            label: "File",
            slug: "file"
          }
        ],
        selectedValueType: "text"
      },
      maxAllowed: {
        name: 50,
        value: 300
      },
      formError: {
        name: false,
        group: false,
        value: false,
        value_type: false
      },
      addNewGroup: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      group: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(20)
      },
      selectedValueType: {
        required
      },
      value:  {
        required
      }
    }
  },
  computed: {
    isVideo(){
      if(this.form.selectedValueType !== 'file'){
        return false
      }

      let videoFormat = ['mp4']

      if(this.uploadedFile){
        let value = this.uploadedFile.split('.').slice(-1).pop();
        return videoFormat.includes(value);
      }

      let value = this.form.value.split('.').slice(-1).pop();
      return videoFormat.includes(value);
    },

    readOnly(){
      return !!this.isUpdating;
    },
    showMedia() {
      if (!this.isUpdating) return this.userFile

      else {
        if (!this.uploadedFile) {
          return `/storage/settings/${this.form.value}`
        } else {
          return this.userFile
        }
      }
    },
    fieldToShow() {
      return this.form.selectedValueType;
    },
    modalSubmitBtnText() {
      return this.isUpdating ? 'Save Changes' : `Add Setting`;
    },
    keyLength: function () {
      return this.form.name?.length;
    },
    valueLength() {
      return this.form.value.length;
    },
    isUpdating() {
      return !!this.setting;
    }
  },
  watch: {
    userFile : {
      handler(){
        // this.checkFileType();
      },
      immediate : true,
      deep : true,
    },
    'form.selectedValueType': {
      handler(nv) {
        // continue only when adding new
        if (this.isUpdating) return false;
        this.form.value = (nv === 'bool' && this.form.value === '') ? false : '';
      },
      deep: true,
    },
    show: function (nv) {
      this.showModal = nv;
    },
    setting: {
      handler(nv) {
        // only populate for updating
        if (!this.isUpdating) return false;
        // customize modal
        this.modal = {
          title: 'Update Setting Item',
          headerClasses: 'bg-dark text-light',
          closeBtnClasses: 'text-light',
        };
        const newForm = {
          ...this.form,
          name: nv.key,
          group: nv.group,
          value: nv.value,
          options : nv.options,
          selectedValueType: nv.value_type,
          value_type: nv.value_type
        };
        // populate values
        this.form = newForm;
        if (nv.value_type === 'file') {
          this.userFile = nv.value
        }
        if(nv.options){
          this.form.dayOff = nv.value;
        }
      },
      deep: true,
      immediate : true,
    },
    'form.dayOff' : {
      handler(val){
        this.form.value = val;
      }
    },
    'form.name': function (nv) {
      // format input
      if (nv) {
        this.form.name = nv
          .toLowerCase()
          .replace(' ', '_')
        ;
      }
      // handle input errors
      this.formError.name = ErrorHelper.errAll(this.$v, 'form.name');
    },
    'form.group': function () {
      this.formError.group = ErrorHelper.errAll(this.$v, 'form.group');
    },
    'form.value': function () {
      this.formError.value = ErrorHelper.errAll(this.$v, 'form.value');
    }
  },
  mounted() {
    this.makeGroupOptions();
  },
  methods: {
    triggerClick(name) {
      let elem = this.$refs[name]
      elem.click()
    },
    showImages(value, local = null) {
      if (local) return local
      else return `/storage/settings/${encodeURI(value)}`
    },

    handleFileUpload(e) {
      this.form.value = this.$refs.uploadMedia.files[0];
      let extension = this.$refs.uploadMedia.files[0]['type'].split('/').slice(-1).pop();
      let url = URL.createObjectURL(this.form.value)
      this.uploadedFile = url + '.' + extension;
      this.userFile = url ;
    },
    openModal() {
      this.showModal = true;
    },
    async handleSubmit() {
      // check if form has any error
      if (this.$v.$invalid) {
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });
        return false;
      }
      const response = await this.sendData();
      if (response) {
        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: `Setting ${this.isUpdating ? 'Updated' : 'Created'}`,
          body: `'${response.data.key}' ${this.isUpdating ? 'updated' : 'created'} successfully`,
          type: 'su',
          autoHideDelay: 2,
          closeCallback: () => window.location.reload()
        });
        // close modal
        this.showModal = false;
      }
    },
    async sendData() {
      this.form.submitting = true;
      // set endpoint and methods
      const endPoint = (this.isUpdating) ? `/admin/settings/${this.setting.id}` : `/admin/settings`;
      const method = (this.isUpdating) ? 'patch' : 'post';
      let {
        name: name,
        group: group,
        selectedValueType: value_type,
        value: value
      } = this.form
      let config = {headers: {'Content-Type': 'multipart/form-data'}}
      let formData = {
        _method  : method,
        name : name,
        group : group,
        value_type : value_type,
        value : value
      }
      if(!this.isUpdating){
        formData['is_deletable'] = this.form.isDeletable? 1 : 0
        formData['admin_only'] = this.form.isDeletable? 1 : 0
      }
      if(value_type === 'file'){
        // If value is file The Data will be Convert to Form Data
        // Other wise it will be Flat JSON
        let userData = new FormData();
        for(let key in formData){
          userData.append(key, formData[key])
        }
        formData = userData;
      }
      return axios({
        method: 'POST',
        url: endPoint,
        data: formData,
        config: config
      })
        .then(data => {
          this.form.submitting = false;
          this.showModal = false
          return data
        })
        .catch((e) => {
          this.form.submitting = false;
          // show notification about error
          NotificationHelper.showServerError(this.$snotify, e);
          // map errors to display in form
          ErrorHelper.mapServerError(this.formError, e);
          return false;
        });
    },
    makeGroupOptions() {
      if (!this.groups.length) {
        this.form.groupOptions = [{
          id: 'app',
          label: 'app'
        }];
        return;
      }
      this.form.groupOptions = this.groups.map(group => ({id: group, label: group}));
    }
  }
}
</script>
<style scoped>
.user-uploaded-image{
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: auto;
  justify-content: center;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
#groupAddEditGroup {
  flex-wrap: nowrap;
}
input[id="settingGroup"] {
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
/*extends switch size*/
/* for sm */
.user-image {
  max-height: 100px;
  width: auto;
  object-fit: contain;
  margin-top: 15px;
}
.custom-switch.custom-switch-sm .custom-control-label {
  padding-left: 1rem;
  padding-bottom: 1rem;
}
.custom-switch.custom-switch-sm .custom-control-label::before {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}
.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}
.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}
/* for md */
.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}
.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}
.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}
.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}
/* for lg */
.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}
.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}
.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}
.custom-switch.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}
/* for xl */
.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}
.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}
.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}
.custom-switch.custom-switch-xl .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}
</style>
