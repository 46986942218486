import { SnotifyPosition, SnotifyStyle } from 'vue-snotify';
import _ from 'lodash';

export default class NotificationHelper {

    /**
     * Shows simple notification
     * @param notificationObj
     * @param title
     * @param body
     * @param type  (su,in,wa,er,si)
     * @param closeCallback
     * @param autoHideDelay
     */
    static showSimpleNotification(notificationObj, {title, body, type, closeCallback, autoHideDelay=3}) {

        const realType = getType(type);

        const config = {
            timeout: (autoHideDelay * 1000),
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.rightTop
        };

        const notification = notificationObj[realType](body, title, config);

        // call callback to inform about function close
        notification.on('beforeHide', t => {
            if (typeof closeCallback === 'function') {
                closeCallback(t);
            }
        })

    }

    static showServerError(notificationObj, errorResponse, title='Invalid Data') {

        let errMsg = errorResponse?.response?.data?.message;

        // show notification if there's message available
        if (errMsg) {

            // don't display sql errors
            const isSqlError = errMsg.includes('SQL');
            errMsg = isSqlError ? 'Server error, Please try again in a while' : errMsg;

            NotificationHelper.showSimpleNotification(notificationObj, {
                title,
                body: errMsg,
                type:'er',
                autoHideDelay: 4
            });
        }
    }

    static showConfirm(notificationObj, {title, body, yesCallback, noCallback, yesBtnLabel='Yes', noBtnLabel='No'}) {

        const config = {
            closeOnClick: false,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            backdrop: .6,
            buttons: [
                {
                    text: yesBtnLabel,
                    action: toast => {
                        notificationObj.remove(toast.id);
                        if (typeof yesCallback === 'function') {
                            yesCallback();
                        }
                    }
                },
                {
                    text: noBtnLabel,
                    action: toast => {
                        notificationObj.remove(toast.id);
                        if (typeof noCallback === 'function') {
                            noCallback();
                        }
                    }
                }
            ]
        };

        notificationObj.confirm(body, title, config);
    }
}

function getType(type) {

    const allowedTypes = {
        su: 'success',
        in: 'info',
        wa: 'warning',
        er: 'error',
        si: 'simple'
    };

    // check if type is allowed
    const isTypeAllowed = Object.keys(allowedTypes).includes(type);

    if (isTypeAllowed) {
        return allowedTypes[type];
    } else {
        console.log(`Notification type not allowed: ${type}`);
    }


    switch (type) {
        case true:
            return  allowedTypes.su;

        case false:
            return allowedTypes.er;

        default:
            return allowedTypes.si;
    }

}
