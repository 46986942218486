<template>
    <span
      :class="[classesMix.join(' '), classes]"
      :style="{fontSize: `${fontSize}px`, ...styles}"
    >
        <slot></slot>
    </span>
</template>

<script>
export default {
  name: "badge-comp",
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['pr', 'se', 'su', 'wa', 'da', 'in', 'li', 'dar'].indexOf(value) !== -1
      },
      default: 'pr'
    },
    shape: {
      type: String,
      validator: function (value) {
        return ['rounded', 'squire'].indexOf(value) !== -1
      },
      default: 'rounded'
    },
    fontSize: {
      type: Number,
      default: 12
    },
    classes: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    classesMix: function () {

      const typeMap = {
        pr: 'primary',
        se: 'secondary',
        su: 'success',
        wa: 'warning',
        da: 'danger',
        in: 'info',
        li: 'light',
        dar: 'dark'
      };

      const classes = [
        'badge',
        `badge-${typeMap[this.type]}`,
        (this.shape === 'rounded') ? 'badge-pill' : ''
      ];

      return classes;
    }
  }
}
</script>

<style scoped>
</style>
