<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Defined Groups</div>
          <group-action
            v-if="aclPermissions.create"
            key="add-group"
            :permissions="permissions"
            modal-id="add-group"
          >
            <template #default="sProps">
              <button
                class="btn btn-sm btn-primary p-2"
                @click="sProps.openModal"
              >
                <i class="icon-add"></i>Add New
              </button>
            </template>
          </group-action>
        </div>
        <div class="card-body">

          <div class="table-container">
            <div class="table-responsive">
              <table class="table custom-table">
                <thead>
                <tr>
                  <th>Group</th>
                  <th>Permissions</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!groups.length">
                  <td class="lead text-center" colspan="4">There's no group available.</td>
                </tr>
                <tr
                  v-for="group in groups"
                  v-else
                  :key="group.id"
                >
                  <td>
                    {{ group.name }}
                  </td>
                  <td>
                    <div v-if="!group.permissions.length">
                      <badge-comp type="da">NO Permission Defined</badge-comp>
                    </div>
                    <div v-else class="d-flex flex-column">
                      <code
                        v-for="permission in group.permissions"
                        class="mr-2"
                        :key="permission.name + permission.id"
                      >
                        {{ permission.name }}
                      </code>
                    </div>
                  </td>
                  <td>
                    {{ group.description }}
                  </td>
                  <td class="text-center">
                    <div
                      v-if="aclPermissions.viewAction"
                      class="btn-group dropdown"
                    >
                      <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-boundary="element"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i class="icon-menu"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a
                          v-if="aclPermissions.update"
                          class="dropdown-item open-modal"
                          href="#"
                          @click="showEditGroupModal(group.id)"
                        >
                          Update Permissions
                        </a>
                        <delete-action
                          v-if="aclPermissions.delete"
                          :confirm-body="`This will delete the '${group.name}' group permanently.`"
                          :confirmation="true"
                          :url-arguments="group.id"
                          endpoint="/admin/acl/groups"
                        >
                          <template #default="{click}">
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="click"
                            >
                              Delete
                            </a>
                          </template>
                        </delete-action>
                      </div>
                    </div>
                    <group-action
                      :key="group.id"
                      :modal-id="getMid(group.id)"
                      :permissions="permissions"
                      :group="group"
                      :show="modalStatus.editGroup[getMid(group.id)]"
                      @group-action-hide="hideEditGroupModal(group.id)"
                    />

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatModalId } from '../../js/helpers/Helper';
import GroupAction from '../DoActions/ACL/GroupAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from "../Utility/BadgeComp";

export default {
  name: "groups-page-content",
  props: {
    permissions: {
      type: [Object, Array],
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    aclPermissions: {
      type: Object,
      required: true
    }
  },
  components: {BadgeComp, DeleteAction, GroupAction},
  data() {
    return {
      modalStatus: {
        editGroup: {}
      }
    }
  },
  mounted() {
    this.mapGroupsToModal();
  },
  methods: {
    showEditGroupModal(groupId) {
      const mid = this.getMid(groupId);
      this.modalStatus.editGroup[mid] = true;
    },

    hideEditGroupModal(groupId) {
      const mid = this.getMid(groupId);
      this.modalStatus.editGroup[mid] = false;
    },

    mapGroupsToModal() {
      const modals = {};

      this.groups.map(({id}) => {
        const mid = this.getMid(id);
        modals[mid] = false;
      });

      this.modalStatus.editGroup = modals;
    },

    getMid(id) {
      return formatModalId(id);
    },

  }
}
</script>

<style scoped>

</style>
