<template>
  <div class="user-avatar-image-container">
    <div v-if="name" class="user-avatar-image">
      {{ imageChars() }}
    </div>
    <img v-else :src="imagePath">
  </div>
</template>

<script>
import { userAvatarChar} from "../../js/helpers/Helper";

export default {
  name: "user-avatar",

  props: {
    name: {
      type: String,
      default: ''
    },
    imagePath: {
      type : String,
    }
  },
  data : ()=> ({

  }),

  methods: {
    imageChars(){
      return userAvatarChar(this.name);
    }
  }
}
</script>
<style scoped lang="scss">

.user-avatar-image-container {
  background-color: #013243;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
  text-align: center;
}
</style>
