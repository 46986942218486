<template>
  <div :id="id"
       :aria-labelledby="label"
       aria-hidden="true"
       class="modal fade"
       role="dialog"
       tabindex="-1"
  >
    <div :class="`modal-dialog modal-dialog-centered modal-${size}`" role="document">
      <div class="modal-content">

        <div :class="`modal-header ${headerClasses}`">
          <h5 :id="label" class="modal-title">
            {{ title }}
          </h5>
          <button :class="`close ${closeBtnClasses}`" aria-label="Close" type="button" @click="modalShowHide(false)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <slot :submit="fireSubmitEvent">
            <h1>Modal Content for: {{ id }}</h1>
          </slot>
        </div>

        <div
          v-if="!hideFooter"
          class="modal-footer custom"
        >
          <div class="left-side">
            <button
              class="btn btn-link danger"
              type="button"
              @click="handleHideOnClose"
            >
              <slot name="cancel-btn-content">Cancel</slot>
            </button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <button
              class="btn btn-link success"
              type="button"
              @click="fireSubmitEvent"
            >
              <slot name="submit-btn-content">Submit</slot>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Fire events
 * submit, cancel, show, hide
 */
export default {
  name: "modal-comp",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    headerClasses: {
      type: String,
      default: ''
    },
    closeBtnClasses: {
      type: String,
      default: ''
    },
    closeOnCancel: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        const allowed = ['sm', 'md', 'lg', 'xl'];
        return allowed.indexOf(value) !== -1
      }
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    idSelector: function () {
      return `#${this.id}`;
    },

    label: function () {
      return `${this.id}Label`;
    },
  },

  watch: {
    show: function (newVal) {
      this.modalShowHide(newVal);
    }
  },

  mounted() {
    this.updateStatusOnShow();
    this.updateStatusOnHide();
  },

  beforeDestroy() {
    $(this.idSelector).off('show.bs.modal', this.fireShowEvent);
    $(this.idSelector).off('shown.bs.modal', this.fireShownEvent);
    $(this.idSelector).off('hide.bs.modal', this.fireHideEvent);
  },

  methods: {

    modalShowHide(shouldShow = true) {

      if (shouldShow) {
        $(this.idSelector).modal();
      }

      const showHide = shouldShow ? 'show' : 'hide';

      $(this.idSelector).modal(showHide);
    },

    handleHideOnClose() {
      if (this.closeOnCancel) {
        this.modalShowHide(false);
        this.fireCancelEvent();
      } else {
        this.fireHideEvent();
      }
    },

    updateStatusOnShow() {
      $(this.idSelector).on('show.bs.modal', this.fireShowEvent);
      $(this.idSelector).on('shown.bs.modal', this.fireShownEvent);
    },

    updateStatusOnHide() {
      $(this.idSelector).on('hide.bs.modal', this.fireHideEvent);
    },

    fireSubmitEvent() {
      this.$emit('submit', this.id);
    },

    fireCancelEvent() {
      this.$emit('cancel', this.id);
    },

    fireShowEvent(e) {
      this.$emit('show', this.id);
    },

    fireShownEvent(e) {
      this.$emit('shown', this.id);
    },

    fireHideEvent(e) {
      this.$emit('hide', this.id);
    }
  }
}
</script>

<style scoped>

</style>
