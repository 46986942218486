<template>
  <modal-comp
    size="md"
    :title="title"
    :id="modalId"
    :show="showModal"
    :hide-footer="true"
    @hide="handleHide()"
  >
    <div class="container">

      <div class="form-group col-12">
        <label for="status" class="col-form-label">
          Status:
        </label>
        <div class="input-group flex-nowrap" id="orderStatusUpdateGroup">
          <treeselect
            id="status"
            placeholder="Select status"
            v-model="selectedOption"
            :options="validOptions"
            :multiple="false"
            :searchable="false"
            :clearable="false"
          >
            <label
              slot="option-label"
              slot-scope="{ node, labelClassName }" :class="[labelClassName,'order-status-select-label']">
              {{ node.label }}
            </label>
          </treeselect>

          <div class="input-group-append text-nowrap">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="showSubmitBtn"
              @click="handleSubmit"
            >
              <span v-if="formSubmitting" class="spinner-border mr-2 align-middle"></span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
      <template v-if="statusTypeOrder">
        <div
          v-if="showDelayOption"
          class="form-group col-12"
        >
          <label for="status" class="col-form-label">
            Add Minutes:
            <form-input-help>Add minutes to order accept time</form-input-help>
          </label>
          <div class="input-group flex-nowrap">
            <treeselect
              placeholder="Select status"
              v-model="addMinute"
              :options="addMinuteOptions"
              :multiple="false"
              :searchable="false"
              :clearable="false"
            >
              <label
                slot="option-label"
                slot-scope="{ node, labelClassName }" :class="[labelClassName,'order-status-select-label']">
                {{ node.label }}
              </label>
            </treeselect>
          </div>
        </div>
      </template>
      <div
        v-if="showMailSendOption"
        class="form-group col-12"
      >
        <label class="col-form-label">
          Send Update Email:
          <form-input-help>If enabled system will send email to customer about new status change</form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="sendStatusEmail"
            @change="()=>sendStatusEmail=!sendStatusEmail"
            enable-text="Will send email to customer"
            disable-text="Mail will not be sent to customer"
          />
        </div>
      </div>


    </div>
  </modal-comp>
</template>
<script>
import ModalComp from '../Utility/ModalComp'
import Treeselect from '@riophae/vue-treeselect';
import NotificationHelper from "../../js/helpers/NotificationHelper";
import FormInputHelp from '../Utility/FormInputHelp';
import SwitchComp from '../Utility/SwitchComp';

export default {
  name : 'status-update-action',
  components : {ModalComp, Treeselect, FormInputHelp, SwitchComp},
  props : {
    currentOption : {
      required : false,
      type : String
    },
    subjectId : {
      required : true,
    },
    modalId : {
      required : false,
      type : String,
      default : 'updateStatus'
    },
    availableOptions : {
      required : false,
      type : Array
    },
    actionLink : {
      required : true,
      type : String,
    },
    showModal : {
      required : true,
      type : Boolean,
    },
    title: {
      required : false,
      default : 'Update Order Status'
    },
    successTitle : {
      required : false,
      default : 'Status Updated'
    },
    successMessage : {
      required : false,
      default: 'Status Successfully Updated'
    },
    statusTypeOrder: {
      type: Boolean,
      default: true
    }
  },

  data : ()=>({
    selectedOption : "",
    modalShow : false,
    statusOptions : [],
    endpoint : "",
    formSubmitting : false,
    addMinuteOptions: [
      {id: 0, label: 'No Addition'},
      {id: 5, label: '5 minutes'},
      {id: 10, label: '10 minutes'},
      {id: 15, label: '15 minutes'},
      {id: 20, label: '20 minutes'},
      {id: 25, label: '25 minutes'},
      {id: 30, label: '30 minutes'},
    ],
    addMinute: 0,
    sendStatusEmail: true
  }),

  computed  : {
    showSubmitBtn(){
      return this.currentOption === this.selectedOption
    },

    showDelayOption() {
      return (this.statusTypeOrder && (this.currentOption === 'pending') && (this.selectedOption === 'processing'));
    },

    showMailSendOption() {

      const options = this.statusTypeOrder
        ? ['pending','processing','delivering']
        : ['pending']
      ;

      return options.includes(this.currentOption);
    },

    validOptions() {

      const options = this.statusTypeOrder
        ? this.orderValidOptions()
        : this.reservationValidOptions()
      ;

      return options.map(status => ({
        id: status,
        label: status
      }));
    }
  },

  mounted() {
    this.makeActionUrl();
  },

  watch : {
    showModal(value) {
      this.modalShow = value
    },
    currentOption(value) {
      this.selectedOption = value;
    },
    actionURL() {
      this.makeActionUrl()
    },
    selectedOption(nv) {
      if (nv !== 'processing') {
        this.addMinute = 0;
      }
    }
  },

  methods : {
    makeActionUrl(){
      this.endpoint = `${this.actionLink}/${this.subjectId}`
    },

    handleSubmit(){
      this.makeActionUrl()
      this.formSubmitting = true

      let data = {
        status: this.selectedOption,
        sendEmail: this.sendStatusEmail
      }

      if (this.statusTypeOrder) {
        data.addMinutes = this.addMinute;
      }

      axios
        .patch(this.endpoint, data)
        .then((data) => {

          NotificationHelper.showSimpleNotification(this.$snotify, {
            title: `${this.successTitle}`,
            body: `${this.successMessage}`,
            type: 'su',
            autoHideDelay: 2,

            closeCallback: () => window.location.reload()
          });

          this.handleHide();

          this.addMinute = 0;
          this.sendStatusEmail = true;

        })
        .catch((e) => {
          NotificationHelper.showServerError(this.$snotify, e);
        })
        .finally(() => {
          this.formSubmitting = false;
        })
      ;

    },

    handleHide(){
      this.modalShow = false
      this.$emit('user-action-hide');
    },

    orderValidOptions() {

      switch (this.currentOption) {
        case 'pending':
          return ['pending','processing','cancelled'];

        case 'processing':
          return ['processing','delivering','delivered','cancelled'];

        case 'delivering':
          return ['delivering','delivered','cancelled'];

        case 'delivered':
          return ['delivered'];

        case 'cancelled':
          return ['cancelled'];

        default:
          return []
      }
    },

    reservationValidOptions() {

      switch (this.currentOption) {
        case 'pending':
          return ['pending','confirmed','cancelled'];

        case 'confirmed':
          return ['confirmed'];

        case 'cancelled':
          return ['cancelled'];

        default:
          return []
      }
    }
  }
}
</script>
<style lang="scss"></style>
